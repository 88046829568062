export let AJAX = {

	paging: function(hash, paging, pageSelected, pageIsSet)
	{
        console.log(paging);

        //console.warn('paging', pageIsSet);

        // change loading state
        app.VIEW[hash].DOM.table.removeClass('loading empty error');
        
        if( pageIsSet ) {
            app.VIEW[hash].DOM.table.find('tr').not('.paging,.th').remove();
        }

        app.DOM.content_load.find('#paging').remove();

		// do nothing error trigger
		if( paging === false ) {
			app.DOM.content_load.find('#filter').hide();            
            app.VIEW[hash].DOM.table.addClass('error');
			return;
		}
        
        if( !paging.total ) {
            app.DOM.content_load.find('#filter').hide();
            app.VIEW[hash].DOM.table.addClass('empty');
        }

		paging.total = ( paging.total ) ? paging.total.toLocaleString() : 0;        

		let html = `<div id="paging">
			<div id="paging-total" class="a">Total: ${paging.total}</div>
			${AJAX.paging_controls(paging, pageSelected)}
			<div id="paging-showing" class="a">${paging.xofx}</div>
		</div>`;

        app.DOM.content_load.find('table').before(html);

        // setup events
        AJAX.paging_events(hash);
	},

    paging_controls: function(paging, pageSelected)
    {
        let options = '';

        if( paging.pages ) {
            for(let i = 1; i <= paging.pages; i++){
                let selected = ( i === pageSelected ) ? ' selected="selected" ' : '';
                options += `<option ${selected}>${i}</option>`;
            }
        }

        let select = `<select id="paging-select">${options}</select>`,
            buttonPrevColour = ( paging.page === 1 ) ? 'button-grey' : 'button-primary',
            buttonPrevPage = pageSelected - 1,
            buttonNextColour = ( paging.page === paging.pages) ? 'button-grey' : 'button-primary',
            buttonNextPage = pageSelected + 1;

        let pagingControls = `<div id="paging-controls">
            <a data="1" class="button ${buttonPrevColour}" id="paging_start"><i class="fa fa-chevron-double-left"></i></a>
            <a data="${buttonPrevPage}" class="button ${buttonPrevColour}" id="paging_prev"><i class="fa fa-chevron-left"></i></a>
            ${select}
            <a data="${buttonNextPage}" class="button ${buttonNextColour}" id="paging_next"><i class="fa fa-chevron-right"></i></a>
            <a data="${paging.pages}" class="button ${buttonNextColour}" id="paging_end"><i class="fa fa-chevron-double-right"></i></a>
        </div>`;

        if( !paging.pages || paging.pages === 1 ){
            pagingControls = '';
        };

        return pagingControls;
    },

    paging_events: function(hash)
    {
        app.DOM.content_load.find('#paging select').on('change', function(){

            if( $(this).closest('#paging').hasClass('ajax') ) {
                return;
            }

            $(this).closest('#paging').addClass('ajax');
            
            app.VIEW[hash].ajax(parseInt(this.value));
        });
        
        app.DOM.content_load.find('#paging a').on('click', function(){
            
            if( $(this).hasClass('button-grey') || $(this).closest('#paging').hasClass('ajax') ) {
                return;
            }

            $(this).closest('#paging').addClass('ajax');

            app.VIEW[hash].ajax(parseInt($(this).attr('data')));
        });

        app.FORM.start_filter();
    },

    /**
     * Check vehicle suspended state
     *
     * @url /api/veh_is_suspended:id
     */
    veh_is_suspended: function(tbl, hash, veh, ts, trailer, ts_linked, $upcoming)
    {
        // do not proceed
        if( app.check_connection() === false ) {
            if( app.VIEW[hash].hasOwnProperty('veh_is_suspended_callback') ) {
                app.VIEW[hash].veh_is_suspended_callback(false, trailer);
            }
            return;
        }

        var asset = ( trailer ) ? 'Trailer' : 'Asset';

        // AJAX
        var ajax = $.ajax({
            'url': app.get_api_url('veh_is_suspended/' + veh.id),
            'type': 'POST',
            'timeout': app.TIMEOUT_AJAX,
            'data': app.get_api_data({
                'is_suspended': veh.is_suspended,
                'tbl': tbl,
                'rep_after_fail': app.CACHE.USR.operator_rep_after_fail
            })
        });

        var id_name = ( veh.reg ) ? veh.reg : veh.serial;
        
        // DONE
        ajax.done(function(json){
            // SUCCESS
            if( json.status === 'success' && json.data.veh ){

                // upcoming dates
                app.UPCOMING.paint(veh.id, $upcoming, json.data, trailer);

                // SUSPENDED
                // or
                // VOR (daily check)
                if( json.data.veh === '1' || (tbl === 'rep' && json.data.veh === '2') ) {

                    // delete report
                    app.FORM.save_offline(tbl, 'delete', {}, true, ts, false, false, true);
                    
                    // delete associated report
                    if( ts_linked ) {
                        app.FORM.save_offline(tbl, 'delete', {}, true, ts_linked, false, false, true);
                    }

                    // msg user
                    app.show_alert(`The compliance management system (CMS) shows this ${asset} "${id_name}" as being ${app.VEH_STATUS[json.data.veh]} and therefore you will be unable to complete a ${app.TBL[tbl].i}.\n\nYou will be redirected back to the home screen.`, "Unable to continue");

                    // redirect
                    app.redirect('home');

                // NOT SUSPENDED
                } else if( (tbl === 'mai' && json.data.veh !== '1' && veh.is_suspended === '1' ) || (tbl === 'rep' && json.data.veh === '0' && veh.is_suspended !== '0' ) ) {

                    if( app.HASH === hash ) {
                        app.VIEW[hash].DOM.is_suspended.html('<p class="f-18 b">After checking online the Asset "'+id_name+'" is currently NOT suspended, you can now add Inspections to this Asset</p>').addClass('bg-green');

                        // allow reports
                        if( tbl === 'rep' ) {
                            app._('btn_next').addClass('button-primary').removeClass('button-grey');
                        }
                    }
                }

                // callback function
                if( json.data.veh !== '1' && app.VIEW[app.HASH].hasOwnProperty('veh_is_suspended_callback') ) {
                    app.VIEW[hash].veh_is_suspended_callback(json, trailer);
                }

                // UPDATE VEHICLE: STATUS DIFFERENT
                if( json.data.veh !== veh.is_suspended ) {
                    app.FORM.save('veh', 'edit', {'is_suspended': json.data.veh}, false, true, veh.id);
                }

                // RE-ENABLE AJAX BUTTON
                if( app._('btn_ajax') && json.data.veh !== '0' || (json.data.safety_fails && json.data.safety_fails !== '0') ) {

                    var $btn = app._('btn_ajax');

                    if( $btn.length === 2 ) {
                        $btn = ( trailer ) ? $btn.last() : $btn.first();
                    }

                    $btn.removeClass('button-grey').addClass('button-primary');
                }

            } else {

                if( app.VIEW[hash].hasOwnProperty('veh_is_suspended_callback') ) {
                    app.VIEW[hash].veh_is_suspended_callback(false, trailer);
                }
            }
        });

        ajax.fail( function(e){

            // callback function
            if( app.VIEW[hash].hasOwnProperty('veh_is_suspended_callback') ) {
                app.VIEW[hash].veh_is_suspended_callback(false, trailer);
            }
        });
    },

	/**
     * ajax request Check vehicle defects
     *
     * @url /api/veh_defects
     */
    veh_defects: function(vehicle_id)
    {
        var hash = app.HASH;

        var ajax = $.ajax({
            url: app.get_api_url('veh_defects'),
            data: app.get_api_data({'vehicle_id': vehicle_id}),
            method: 'POST'
        });

        ajax.done(function(r){

            // success
            if( r && r.status === 'success' && r.data && r.data.defects && r.data.defects.length > 0 && app.HASH === hash && vehicle_id === r.data.vehicle_id ) {
                app.AJAX.veh_defects_success(hash,r.data.defects);
            }
        });
    },

    get_def_type_icon: (type) => {
        switch(type.toLowerCase()) {
            case 'safety fail': return 'fa fa-exclamation-triangle red';
            case 'fail': return 'fa fa-exclamation-circle orange';
            case 'in-service defect': return 'fa fa-exclamation fa-veh-def grey';
            default: return ''
        }
    },

    /**
     * Add Defects to DOM View
     */
    veh_defects_success: function(hash, def)
    {
        app.VIEW[hash].DOM.ajax_veh = app.DOM.content_load.find('#ajax-veh').addClass(hash+'-que');

        // paint
        var html = `<h3 id="ajax-veh-toggle">
                        <i class="fas fa-exclamation-triangle"></i>
                        ${def.length} pre-existing ${Handlebars.helpers.plural(def.length, 'defect')}
                        <span class="state state-hide">Hide</span>
                        <span class="state state-show">Show</span>
                        <i class="fas fa-chevron-circle-down state-show"></i>
                        <i class="fas fa-chevron-circle-up state-hide"></i>
                    </h3>
                    <div id="ajax-veh-defects">`;

        $.each(def, function(k,v){
            html += `<h3 class="ajax-veh-defect">
                    <span style="line-height:14px;font-size:12px;vertical-align:text-bottom;" class="label">
                        Type: 
                        <i style="font-size:12px;vertical-align:middle;position:relative;right:unset;top:unset;padding:0;" class="${AJAX.get_def_type_icon(v.type)}"></i>
                        (${v.type})
                    </span>
                    <span class="label">Q: ${v.label}</span>
                    <span class="imref">A: ${v.answer}</span>
                    <span class="state state-show">Show</span>
                    <span class="state state-hide">Hide</span>
                    <i class="fas fa-chevron-circle-down state-show"></i>
                    <i class="fas fa-chevron-circle-up state-hide"></i>
                </h3>
                <div class="ajax-veh-defect-content">
                    <span class="label">Defect Type:</span>
                    <span class="answer">${v.type}</span>
                    <div class="c"></div>
                    <span class="label">Status:</span>
                    <span class="answer">${v.status}</span>
                    <div class="c"></div>
                    <span class="label">${app.TBL[v.tbl_related].i}:</span>
                    <span class="answer">#${v.tbl_related_id}</span>
                    <div class="c"></div>
                    <span class="label">Date Reported:</span>
                    <span class="answer">${Handlebars.helpers.date(v.date_added, 'datetime')}</span>
                    <div class="c"></div>
                    <span class="label">Reported By:</span>
                    <span class="answer">${v.user_name}</span>
                    <div class="c"></div>`;

                if( v.pics && v.pics.length > 0 ) {
                    html +='<div class="ajax-veh-defect-pics">'+
                            '<img class="ajax-veh-pic-large" style="display:none">';

                    // loop thorugh each pic and add
                    $.each(v.pics, function(key,val){
                        var full = Handlebars.helpers.getFileSlug(v.tbl, val, 'full'),
                            thumb = Handlebars.helpers.getFileSlug(v.tbl, val, 'thumb');
                        html += '<img src="'+thumb+'" data-full="'+full+'">';
                    });

                   html += '</div>';
                }
            html += '</div>';
        });
        
        html += '</div>';


        app.VIEW[hash].DOM.ajax_veh.html(html).show();

        // setup dom
        app.VIEW[hash].DOM.ajax_veh_toggle = app.VIEW[hash].DOM.ajax_veh.find('#ajax-veh-toggle');
        app.VIEW[hash].DOM.veh_defects = app.VIEW[hash].DOM.ajax_veh.find('.ajax-veh-defect');
        app.VIEW[hash].DOM.ajax_veh_img = app.VIEW[hash].DOM.ajax_veh.find('img');

        // setup events
        app.AJAX.veh_defects_success_events(hash);
    },

    /**
     * Attach events to succesfully veh_defects lookup
     */
    veh_defects_success_events: function(hash)
    {
        // toggle: all
        app.VIEW[hash].DOM.ajax_veh_toggle.on('click', function(){
            $(this).toggleClass('open');
        });

        // toggle: defect
        app.VIEW[hash].DOM.veh_defects.on('click', function(){
            $(this).toggleClass('open');
        });

        // toggle: img
        app.VIEW[hash].DOM.ajax_veh_img.on('click', function(){

            // larger image
            var $parent = $(this).parent(),
                $large = $parent.find('img').first();

            $(this).toggleClass('open');

            // find other <img> open
            $parent.find('img.open').not($(this)).removeClass('open');

            // toggle source
            if( $(this).hasClass('open') ) {
                $large.attr('src', $(this).attr('data-full')).show();
            } else {
                $large.hide();
            }
        });
    },

};