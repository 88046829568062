import * as Handlebars from 'handlebars';
import {CONFIG} from "../config";

export let TPL = {

    fue_exp: data =>
    {
        // global data
        app.DATA = {};
        app.DATA.RESULT = app.TPL.get_row('fue_exp', app.URI[1]);

        // template data
        data.RESULT = app.DATA.RESULT;
        data.RESULT_PHO = TPL._get_photos(data.RESULT ? data.RESULT : {}, 4);

        if( data.RESULT.vehicle_id ) {
            data.VEH = app.TPL.get_row('veh', data.RESULT.vehicle_id);
            data.VEH_TYP = app.TPL.get_row('veh_typ', data.VEH.vehicle_type_id);
            data.VEH_NAME = app.TPL.getVehicleName(data.VEH_TYP, data.VEH);
        }

        data.FUEL_TYPES = app.CACHE.FUE_EXP_TYPES;
        data.FUEL_SOURCES = app.CACHE.FUE_EXP_SOURCES;
        data.COST_TYPES = app.CACHE.COS_TYP;

        return data;
    },

    get_row: function(tbl, identifier, field)
    {
        var index = app.cache_get_index(tbl, identifier, field);

        if( index === false ) {
            return false;
        }

        if( app.CACHE[tbl.toUpperCase()][index] === undefined ) {
            return false;
        }

        return $.extend({}, app.CACHE[tbl.toUpperCase()][index]);
    },

    _get: function(tbl, order_field, order_dir, dateContainer, hideDeleted, hideOffline, hideExpired, limit, limitUser)
    {
        // make result into new clean array
        var tmp = ( typeof(tbl) === 'string' ) ? app.CACHE[tbl.toUpperCase()].slice(0) : tbl.slice(0),
            result = [];

        // create clean instance of reference
        $.each(tmp, function(k,v){
            result.push($.extend({}, v, true));
        });

        // Hide Deleted
        if( hideDeleted === true ) {
            result = result.filter(function(r){
                if( r.hasOwnProperty('is_deleted') === false ){
                    return r;
                }
            });
        }

        // Hide Offline
        if( hideOffline === true ) {
            result = result.filter(function(r){
                if( r.hasOwnProperty('id') ){
                    return r;
                }
            });
        }

        // Hide Expired
        if( hideExpired === true ) {
            var ts_today = app.DATE.timestamp(app.DATE.format('db') + ' 00:00:00');

            result = result.filter(function(r){
                if( app.DATE.timestamp(r.date_start) >= ts_today ){
                    return r;
                }
            });
        }

        // limit records to user
        if( limitUser === true ) {
            result = result.filter(function(r){
                if( r.hasOwnProperty('user_id') && r.user_id === app.CACHE.USR.id ){
                    return r;
                }
            });
        } else if( limitUser === 'not' ) {
            result = result.filter(function(r){
                if( r.hasOwnProperty('user_id') && r.user_id !== app.CACHE.USR.id ){
                    return r;
                }
            });
        }

        // ORDER
        if( order_field !== undefined && order_field !== false ) {
           result = this._sort(result, order_field, order_dir);
        }

        // LIMIT
        if( limit && $.isNumeric(limit) ) {
            result = result.splice(0,limit);
        }

        // put results in month container
        if( dateContainer !== undefined && dateContainer !== false ) {
            result = this._get_date_container(dateContainer, result, order_field);
        }

        return result;
    },
    
    get_vehicle_type_from_vehicle_id: function(vehicle_id)
	{
		var fields = false,
			veh = app.TPL.get_row('veh', vehicle_id);
        
        // check & get vehicle exists
        if( veh ) {
	        return app.TPL.get_row('veh_typ', veh.vehicle_type_id);
	    }

		return fields;
	},

    _get_photo_fields: function(count, local)
    {
        var fields = [];

        // check count
        if( $.isNumeric(count) === false ) {
            count = app.PHOTOS;
        }

        for(var i = 1; i <= count; i++){

            // field
            var f = ( i === 1 ) ? 'file' : 'file'+i;

            // is field local?
            if( local && !app.WEBAPP ) {
                f += '_local';
            }

            // add field to fields
            fields.push(f);
        }

        return fields;
    },

    _sort: function(result, order_field, order_dir)
    {
        if( order_field === 'last_name' ) {

            ///////////////
            // FIRST/LAST NAME
            ///////////////
            if( order_dir === 'desc' ){
                result.sort(function(a,b){ 
                    return b.last_name.localeCompare(a.last_name) || b.first_name.localeCompare(a.first_name);
                });
            } else {
                result.sort(function(a,b){
                    return a.last_name.localeCompare(b.last_name) || a.first_name.localeCompare(b.first_name);
                });
            }

        } else {

            ///////////////
            // DEFAULT
            ///////////////
            result.sort(function(a,b){
                if( order_dir === 'desc' ) {
                   return (b[order_field] > a[order_field]) ? 1 : ((a[order_field] > b[order_field]) ? -1 : 0);
                } else {
                   return (a[order_field] > b[order_field]) ? 1 : ((b[order_field] > a[order_field]) ? -1 : 0);
                }
            });
        }

        return result;
    },

    setup_change_month: function()
    {   
        // object property name
        var prop = app.HASH + '_month';

        app.DOM.content_load.find('#month_filter').change(function(){

            if( this.value === '' ) {
                delete app.CACHE.USR.settings[prop];
            } else {
                app.CACHE.USR.settings[prop] = this.value;
            }

            // user settings
            app.cache_save('usr');

            // reload page
            app.redirect();
        });
    },

    /**
     * 1) Update <html> <body> page name
     * 2) Scroll to top of page
     * 3) Change header title
     */
    page_update: function(direction)
    {
        var page = 'page-' + app.HASH,
            uri = ( app.URI[1] ) ? ' uri-' + app.URI[1] : '';

        // change html & body id
        $('html').prop('class', page + uri);
        $('body').prop('id', page);

        // check if you are going forward if so scroll to top
        $(window).scrollTop(0);
    },

    /**
     * Check history of user so we can see which animation direction to use if any
     *
     * Also track the steps of the user, so we know exactly which pages visited
     * 
     * @return boolean|string
     */
    get_history_direction: function()
    {
        // track all page steps so we can identify last page
        if( app.STEPS.indexOf(app.HASH) !== -1 ) {
            app.STEPS.splice(app.STEPS.indexOf(app.HASH), 1);
        }

        // save uri of user
        if( app.URI.length === 1 ) {
            app.STEPS.push(app.HASH);
        } else {
            app.STEPS.push(app.URI.join('/'));
        }

        // UPDATE HISTORY
        app.HISTORY.push(app.HASH);

        // check length of
        if( app.HISTORY.length === 1 ) {
            return '';
        }

        if( app.HASH === 'home' ) {
            return app.ANIMATION.type + app.ANIMATION.right + app.ANIMATION.end;
        } else {
            return app.ANIMATION.type + app.ANIMATION.left + app.ANIMATION.end;
        }
    },

    action: function(view)
    {
        // place container into var
        var direction = this.get_history_direction(),
            tpl_data = this.get_tpl_data(),
            speed = app.ANIMATION.speed;
        // if debug compile view with data
        let html = app.TEMPLATES[app.HASH](tpl_data);

        // do nothing if no data
        if( tpl_data === undefined || tpl_data === false ) {
            return;
        }

        $('#login-header').remove();
        $('#content-load').scrollTop(0);

        // paint content into page
        if( direction === '' ) {

            app.DOM.content_load.html(html);
            
        } else {

            // animate page in
            //app.DOM.content_load.html(html).addClass('css-animated ' + direction);
            app.DOM.content_load.html(html);

            // remove classes
            //setTimeout(function(){  app.DOM.content_load.removeClass('css-animated ' + direction); }, speed);
        }

        // update page
        app.TPL.page_update(direction);

        // get view
        app.TPL.load_view();

        // scrollToTop
        setTimeout(function(){ $('body').scrollTop(0); }, 100 );
    },

    /**
     * Get specific page view.js
     */
    load_view: function()
    {
        // CHECK IF VIEW ALREADY LOADED
        if( app.VIEW[app.HASH] &&  app.VIEW[app.HASH].init ) {

            console.log('view already loaded (' + app.HASH + '.js) ...');
            app.VIEW[app.HASH].init();

        } else if( app.ROUTE.view === true) {

            // DEBUG ONLY
            // determine which path to get depending on env
            var path = "js/views/" + app.HASH + ".js";

            // CACHE SETTING
            $.ajaxSetup({
                cache: app.CACHE.CACHE_AJAX
            });

            // LOAD JS
            let viewsJS = $.getScript( path );
            console.log('loading view ('+path+') ...');

            // SUCCESS
            viewsJS.done(function(){
                app.VIEW[app.HASH].init();
            });

            // ERROR
            viewsJS.fail(function(e, msg, hint, help) {
                console.log('FAILED: loading view (' + app.HASH + '.js) ');
                console.log(e, msg, hint);
            });

            viewsJS.always(function(){
                $.ajaxSetup({ cache: false });
            });
        }
    },

    /**
     * Decide what data to pass to handlebar template
     */
    get_tpl_data: function(extra)
    {
         var data = {
            'HASH': app.HASH,
            'WEBAPP': app.WEBAPP,
            'ONLINE': app.ONLINE,
            'PHONEGAP': app.PHONEGAP,
            'TBL': app.TBL,
            'URI': app.URI,
            'USR': app.CACHE.USR,
            'URL': app.CACHE.URL,
            'URL_FILES': app.CACHE.URL_FILES,
            'USR_TYP': app.CACHE.USR_TYP,
            'V': app.V,
            'V_LETTER': app.V_LETTER,
            'SITENAME': app.SITENAME,
            'UPPERSITENAME': app.UPPERSITENAME,
        };         

        // get extra data
        if( extra !== undefined ) {
            data = $.extend({}, data, extra);
        }

        // get data depending on page
        if( typeof(app.TPL[app.HASH]) === 'function' ) {
            data = eval('app.TPL.'+app.HASH+'(data)');
        }

        return data;
    },

    /**
     * Get the latest home message
     */
    get_msg_hom: function(data)
    {
        var msg_hom = this._get('msg_hom', 'id', 'desc');

        if( msg_hom && msg_hom[0]) {

            var msg = msg_hom[0];

            if( msg.platform === '1' ) {

                if( app.CACHE.DEVICE.platform === 'android' ) {
                    msg.file = msg.file_android;
                    msg.url = msg.url_android;
                } else {
                    msg.file = msg.file_ios;
                    msg.url = msg.url_ios;
                }

                // clean unwanted
                delete msg.file_android;
                delete msg.file_ios;
                delete msg.url_android;
                delete msg.url_ios;
            }

            if(msg.version_min && !isNaN(msg.version_min) && parseInt(app.VERSION) < parseInt(msg.version_min)) {
                return data;
            }

            if(msg.version_min && !isNaN(msg.version_max) && parseInt(app.VERSION) > parseInt(msg.version_max)) {
                return data;
            }

            data.MSG_HOM = msg;
        }

        return data;
    },

    /**
     * #home - data
     */
    home: function(data)
    {
        // dont look for home messages if already cleared
        if( !app.MSG_HOM ) {
            data = this.get_msg_hom(data);
        }

        // REPORTS
        if( app.CACHE.USR.priv_default !== '0' ) {
            // get reports that are incomplete
            // data.REP_INCOMPLETE = 0;
            data.REP_UNSYNCED = app.SYNC.count_unsynced('rep');
            data.REP_LAST = false;

            $.each(app.CACHE.REP, function(k,v){
                if( v.hasOwnProperty('date_start') && v.hasOwnProperty('date_end') === false ) {

                    // data.REP_INCOMPLETE++;

                    var identifier = ( v.id ) ? v.id : v.ts;

                    if( v.report_id_related ) {

                        var veh,
                            tra;

                        if( !v.is_trailer || v.is_trailer === '0' ) {
                            veh = identifier;
                            tra = v.report_id_related;
                        } else {
                            veh = v.report_id_related;
                            tra = identifier;
                        }

                        data.REP_LAST = 'rep_setup/'+veh+'/'+tra;
                    } else {
                        data.REP_LAST = 'rep/' + identifier;
                    }
                }
            });
        }

        // MAINTENANCE
        if( app.CACHE.USR.priv_mai === '1' ){
            
            // get inspections that are incomplete
            // data.MAI_INCOMPLETE = 0;
            data.MAI_UNSYNCED = app.SYNC.count_unsynced('mai');
            data.MAI_LAST = false;

            $.each(app.CACHE.MAI, function(k,v){

                if( v.hasOwnProperty('date_start') && v.hasOwnProperty('date_end') === false ) {

                    // data.MAI_INCOMPLETE++;

                    var identifier = ( v.id ) ? v.id : v.ts;

                    data.MAI_LAST = 'mai/' + identifier;
                }
            });
        }

        // VEHICLE FUEL
        if( app.FUEL_EXPENSES.check_priv() ){
            data.FUE_EXP_LAST = false;
            data.FUE_EXP_INCOMPLETE = 0;
            data.FUE_EXP_UNSYNCED = app.SYNC.count_unsynced('fue_exp', 'date_start');

            $.each(app.CACHE.FUE_EXP, (k,v) => {
                if( v.hasOwnProperty('date_start') && v.hasOwnProperty('date_end') === false && v.ts ) {
                    data.FUE_EXP_INCOMPLETE++;
                    data.FUE_EXP_LAST = `fue_exp/${v.ts}`;
                }
            });
        }

        // ACCIDENTS
        data.ACC_LAST = false;
        data.ACC_INCOMPLETE = 0;
        data.ACC_UNSYNCED = app.SYNC.count_unsynced('acc');
        $.each(app.CACHE.ACC, (k,v) => {
            if( v.hasOwnProperty('date_start') && v.hasOwnProperty('date_end') === false && v.ts ) {
                data.ACC_INCOMPLETE++;
                data.ACC_LAST = `acc_form/${v.ts}`;
            }
        });
        
        // VEHICLE DEFECTS
        data.VEH_DEF_STANDALONE = false;
        data.VEH_DEF_INSERVICE = false;

        $.each(app.CACHE.VEH_DEF, (k,v) => {
            if( v.ts && !v.id ) {

                if( v.report_id ) {
                    data.VEH_DEF_INSERVICE = `veh_def_form/${v.ts}`;
                } else {
                    data.VEH_DEF_STANDALONE = `veh_def_form/${v.ts}`;
                }

            }
        });


        // msg
        data.UNREAD = this.get_count_msg('date_read');
        data.UNACCEPT = this.get_count_msg('date_accept');
        
        // tacho letters
        if( app.CACHE.USR.opr_priv_tacho === '1' ) {
            data.TACHO_LETTERS = this.get_tacho_letters();

        }

        // both
        data.UNREAD_UNACCEPT = ( data.UNREAD && data.UNACCEPT ) ? true : false;

        // filters
        data.UNREAD_OR_UNACCEPT = ( data.UNREAD || data.UNACCEPT ) ? true : false;

        return data;
    },

    get_tacho_letters: function(f)
    {
        let count = 0;

        $.each(app.CACHE.TCO_DRV_LET, function(k,letter){
            if( letter.sent === 'app' && !letter.driver_acknowledged ) {
                count++;
            }
        });

        return count;
    },

    msg_list: function(data)
    {
        data.UNREAD = this.get_count_msg('date_read');
        data.UNACCEPT = this.get_count_msg('date_accept');

        // msg list
        data.MSG = this._get('msg_usr', 'date_added', 'desc');

        // both
        data.UNREAD_UNACCEPT = ( data.UNREAD && data.UNACCEPT ) ? true : false;

        // filters
        data.FILTERS = ( data.UNREAD || data.UNACCEPT ) ? true : false;

        // filters: overwrite if all same value
        if( data.UNREAD === data.UNACCEPT && data.UNREAD === data.MSG.length ) {
            data.FILTERS = false;
        }

        return data;
    },

    msg: function(data)
    {
        // msg result
        data.RESULT = ( app.URI[2] === 'push' ) ? this.get_row('msg_usr', app.URI[1], 'message_id') : this.get_row('msg_usr', app.URI[1]);

        // redirect if not from push
        if( !data.RESULT && app.URI[2] !== 'push' ) {
            app.redirect('msg_list');
            return;
        }

        // dont change order
        // make date now
        if( data.RESULT.hasOwnProperty('date_read') === false ) {
            data.RESULT.date_read = app.DATE.format('datetime');
        }

        return data;
    },

    get_count_msg: function(f)
    {
        var count = 0;

        $.each(app.CACHE.MSG_USR, function(k,r){
            if( r.hasOwnProperty(f) === false && ( f === 'date_read' || r.is_accept === '1' ) ) {
                count++;
            }
        });

        return count;
    },

    get_recent_vehicles: function(tbl, limit)
    {
        var veh_count = [],
            veh_recent = [];

        var rep = this._get('REP', 'date_added', 'desc', false, false, false, false, false, true);

        // LOOP reports
        $.each(rep, function(k,v){

            if( v.id && veh_count.indexOf(v.vehicle_id) < 0 && veh_count.length < limit ) {

                // setup vehicle-report
                var veh = app.TPL.get_row('veh', v.vehicle_id);

                // count recent vehicles
                if( veh ) {                     

                    // get vehicle type
                    var veh_typ = app.TPL.get_row('veh_typ', veh.vehicle_type_id);

                    if( veh_typ ) {
                        veh_recent.push({
                            'id_name': ( veh.reg ) ? veh.reg : veh.serial,
                            'id_name_type': ( veh.reg ) ? 'id_reg' : 'id_serial',
                            'vehicle_id': v.vehicle_id,
                            'name': app.TPL.getVehicleName(veh_typ, veh),
                            'date': v.date_start,
                            'report_id': v.id, // used for veh_def->report_id,
                            'reg_country': veh.reg_country
                        });
                    }
                }

                // add this vehicle to count
                veh_count.push(v.vehicle_id);
            }
        });

        return veh_recent;
    },

    defect_form: function(tbl, data)
    {

        app.DATA = {};
        data.RESULT = this.get_row(tbl, app.URI[1]);

        if( !data.RESULT ) {
            console.error('no data.RESULT');

            if( tbl === 'veh_def' ) {

                // check if accessing id/ts if ts and doesnt exist 
                if( app.URI[1].length === 10 ) {
                    app.redirect('home');    
                } else {
                    return data;
                }

                return data;
            } else {
                app.redirect(tbl+'_list');
            }

            return false;
        }

        // global data
        app.DATA.RESULT = data.RESULT;

        // vehicle data
        data.VEH = this.get_row('veh', data.RESULT.vehicle_id);

        if( !data.VEH ) {
            app.show_alert('Could not find asset', 'There was a problem');
            return false;
        }

        // vehicle type
        data.VEH_TYP = this.get_row('veh_typ', data.VEH.vehicle_type_id);

        // vehicle name
        data.VEH_NAME = this.getVehicleName(data.VEH_TYP, data.VEH);

        // photos
        var photo_count = ( tbl === 'acc' ) ? 8 : 4;
        data.RESULT_PHO = this._get_photos(data.RESULT, photo_count);

        return data;
    },

    veh_def_form: function(data)
    {
        return this.defect_form('veh_def', data);
    },

    acc_form: function(data)
    {
        data = this.defect_form('acc', data);
        
        if(data.RESULT && typeof data.RESULT.photo_types === 'string') {
            try {
                data.RESULT.photo_types = JSON.parse(data.RESULT.photo_types)
            } catch(err){}
        }
        
        if(data.RESULT && typeof data.RESULT.witnesses === 'string') {
            try {
                data.RESULT.witnesses = JSON.parse(data.RESULT.witnesses)
            } catch(err){}
        }
        
        if(data.RESULT) {
            data.PROGRESS = this._get_acc_progress(data);
        }
        
        app.DATA = data;
        console.log(data);
        return data;
    },

    veh_def_add: function(data)
    {

        // recent reports
        data.VEH_RECENT = this.get_recent_vehicles('veh_def', 5);

        return data;
    },

    acc_list: function(data)
    {
        // recent reports
        data.VEH_RECENT = this.get_recent_vehicles('acc', 5);
      
        // get defects that arent resolved
        data.ACC = this._get('acc', 'date_added', 'desc').filter(function(r){

            if( r.hasOwnProperty('saved') || r.hasOwnProperty('id') ){

                // setup vehicle-report
                var veh = app.TPL.get_row('veh', r.vehicle_id);

                // count recent vehicles
                if( veh ) {

                    // get vehicle type
                    var veh_typ = app.TPL.get_row('veh_typ', veh.vehicle_type_id);

                    if( veh_typ ) {
                        r.id_name = ( veh.reg ) ? veh.reg : veh.serial;
                        r.id_name_type = ( veh.reg ) ? 'id_reg' : 'id_serial';
                     }
                 }

                return r;
            }
        });

        return data;
    },

    /**
     * Get last report from vehicle
     */
    get_rep_last_from_veh: function(vehicle_id, exclude_report_id)
    {
        var rep = false;

        this._get('REP', 'date_added', 'desc').filter(function(r){

            if( ( exclude_report_id === undefined || exclude_report_id && exclude_report_id != r.ts ) &&
                r.vehicle_id === vehicle_id && rep === false &&
                r.hasOwnProperty('date_start')
            ) {
                // get answers
                var identifier = ( r.hasOwnProperty('id') ) ? r.id : r.ts,
                    rep_ans = app.TPL.get_rep_ans(false, identifier);

                // only reports with answers
                if( rep_ans.length > 0 ) {

                    rep = {
                        'date_added': r.date_added,
                        'user_id': r.user_id,
                        'fails': []
                    };

                    if( r.hasOwnProperty('id') ) {
                        rep.id = r.id;
                    } else {
                        rep.ts = r.ts;
                    }

                    // report failures
                    rep.is_fail = false;

                    // LOOP through all answers to check for failed answers
                    $.each(rep_ans, function(key,row){

                        // check question is a safety fail
                        if( row.is_fail === '1' && ( row.hasOwnProperty('is_resolved') === false || row.is_resolved === '0') && row.hasOwnProperty('report_question_id') && app.cache_get_prop('rep_que', row.report_question_id, 'is_safety') === '1' ) {

                            // get ids that need to be checked
                            if( r.hasOwnProperty('id') && row.hasOwnProperty('id') ) {
                                rep.fails.push(row.id);
                            }

                            rep.is_fail = true;
                        }
                    });
                }
            }
        }); 

        return rep;
    },

    _get_report_data: function(data, report_id, count_reports, trailer)
    {
        // get report
        var rep = ( data.hasOwnProperty('REP') ) ? data.REP : this.get_row('rep', report_id);

        // object property suffix
        var suffix = ( trailer ) ? '_TRAILER' : '';

        // check report exists
        if( rep ) {
            data['VEH'+suffix] = this.get_row('veh', rep.vehicle_id);
        }

        // check & get vehicle exists
        if( data['VEH'+suffix] ) {             

            data['VEH_TYP'+suffix] = this.get_row('veh_typ', data['VEH'+suffix].vehicle_type_id);
        
            if( count_reports ) {

                // get reports associated with vehicle
                // exclude latest report
                // var rep_count = 0;
                // app.CACHE.REP.filter(function(r){
                //     if( r.vehicle_id === data['VEH'+suffix].id && r.hasOwnProperty('date_start') ) { rep_count++;}
                // });

                // data['REP_COUNT'+suffix] = rep_count;

                // get last report
                // if( data['REP_COUNT'+suffix] > 0 ) {
                //    data['REP_LAST'+suffix] = this.get_rep_last_from_veh(data['VEH'+suffix].id, report_id);
                // }
            }
        }

        return data;
    },

    _get_maintenance_data: function(data, maintenance_id, count)
    {
        // get maintenance
        var mai = ( data.hasOwnProperty('MAI') ) ? data.MAI : this.get_row('mai', maintenance_id);

        // check maintenance exists
        if( mai ) {
            data['VEH'] = this.get_row('veh', mai.vehicle_id);
        }

        // check & get vehicle exists
        if( data['VEH'] ) {             

            data['VEH_TYP'] = this.get_row('veh_typ', data['VEH'].vehicle_type_id);
        
            if( count ) {

                // get maintenances associated with vehicle
                // exclude latest maintenance
                var mai_count = 0;
                app.CACHE.MAI.filter(function(r){
                    if( r.vehicle_id === data['VEH'].id && r.hasOwnProperty('date_start') ) { mai_count++;}
                });

                data['MAI_COUNT'] = mai_count;
            }
        }

        // set defaults
        data.MAI_ANS_AJAX = false;

        return data;
    },

    get_mai_ans: function(maintenance, maintenance_id, answer)
    {
        var mai_ans = [];

        // get answers from cache
        var i = 1;

        $.each(app.TPL._get('mai_ans'), function(k,v){

            if( v.maintenance_id === maintenance_id ) {

                var ans = v;

                // get relevant question
                if( v.maintenance_question_id ) {
                    ans.que = app.TPL.get_row('mai_que', v.maintenance_question_id);
                }

                // get photos
                ans.pho = app.TPL._get_photos(v, 4);

                // completed
                if( v.hasOwnProperty('id') ) {
                    ans.is_completed = '1';
                }

                mai_ans.push(ans);
            }
        });

        return mai_ans;
    },

    /**
     * Get last inspection from vehicle
     */
    get_mai_last_from_veh: function(vehicle_id, maintenance_id)
    {
        var mai = false;         

        this._get('MAI', 'date_added', 'desc').filter(function(r){

            if( ( maintenance_id === undefined || maintenance_id && maintenance_id != r.ts ) &&
                r.vehicle_id === vehicle_id && mai === false &&
                r.hasOwnProperty('date_start')
            ) {
                // get answers
                var identifier = ( r.hasOwnProperty('id') ) ? r.id : r.ts,
                    mai_ans = app.TPL.get_mai_ans(false, identifier);

                // only maintenances with answers
                if( mai_ans.length > 0 ) {

                    mai = {
                        'date_added': r.date_added,
                        'user_id': r.user_id,
                        'fails': []
                    };

                    if( r.hasOwnProperty('id') ) {
                        mai.id = r.id;
                    } else {
                        mai.ts = r.ts;
                    }

                    // maintenance failures
                    mai.is_fail = false;

                    // LOOP through all answers to check for failed answers
                    $.each(mai_ans, function(key,row){

                        // check question is a safety fail
                        if( row.health === app.CACHE.MAI_ANS_HEALTH.safety.id && ( row.hasOwnProperty('is_resolved') === false || row.is_resolved === '0') && row.hasOwnProperty('maintenance_question_id') ) {

                            // get ids that need to be checked
                            if( r.hasOwnProperty('id') && row.hasOwnProperty('id') ) {
                                mai.fails.push(row.id);
                            }

                            mai.is_fail = true;
                        }
                    });
                }
            }
        });

        return mai;
    },

    _check_continue: function(tbl, row)
    {
        var field = ( tbl === 'rep' ) ? 'report_id' : 'maintenance_id',
            ans = ( tbl === 'rep' ) ? 'REP_ANS' : 'MAI_ANS';

        // dont continue row finished
        if( row.id ) {
            return false;
        }

        // current report progress
        var progress = false; 

        // check if report has been started
        if( tbl === 'rep' && (
                (row.hasOwnProperty('shared') && row.shared !== '') ||
                (row.hasOwnProperty('fuel') && row.fuel !== '') ||
                (row.hasOwnProperty('mileage') && row.mileage !== '') ||
                (row.hasOwnProperty('hours') && row.hours !== '') ||
                (row.hasOwnProperty('adblue') && row.adblue !== '') ||
                (row.hasOwnProperty('height') && row.height !== '')
            )
        ) {

            progress = true;

        } else {
            $.each(app.CACHE[ans], function(k,v){
                if( v[field] === row.ts && v.hasOwnProperty('is_completed') ) {
                    progress = true;
                    return false;
                }
            });
        }

        return progress;
    },

    rep_setup: function(data)
    {
        if( app.URI.length === 0 ) {
            return data;
        }

        // default report
        if(app.URI[1]) {
            data = this._get_report_data(data, app.URI[1], true, false);
            data.REP_VEHICLE = this.get_row('rep', app.URI[1]);
            data.REP_VEHICLE.continue = this._check_continue('rep', data.REP_VEHICLE);
             
        } else {
            data.REP_VEHICLE = false;
        }

        // trailer report
        if( app.URI[2] ) {
            data = this._get_report_data(data, app.URI[2], true, true);
            data.REP_TRAILER = this.get_row('rep', app.URI[2]);
            if(typeof data.REP_TRAILER === "object") {
                data.REP_TRAILER.continue = this._check_continue('rep', data.REP_TRAILER);
            }

        } else {
            data.REP_TRAILER = false;
        }

        // make data globally accessible
        app.DATA.REP_LAST = data.REP_LAST;
        app.DATA.REP_VEHICLE = data.REP_VEHICLE;
        app.DATA.REP_TRAILER = data.REP_TRAILER;

        return data;
    },

    mai_setup: function(data)
    {
        if( app.URI.length === 0 ) {
            return data;
        }

        // default report
        data = this._get_maintenance_data(data, app.URI[1], true, false);
        data.MAI_VEHICLE = this.get_row('mai', app.URI[1]);
        data.MAI_VEHICLE.continue = this._check_continue('mai', data.MAI_VEHICLE);

        // make data globally accessible
        // app.DATA.MAI_LAST = data.MAI_LAST;
        app.DATA.MAI_VEHICLE = data.MAI_VEHICLE;

        data.MAI_CHE = this._get('mai_che', 'name', 'asc');

        return data;
    },

    _get_ans_progress_fake: function(ans, rep, pos)
    {
        $.each(app.CACHE.DEFAULT_QUESTIONS, function(k,v){
            if(
                rep.hasOwnProperty(k) && (
                    (rep.hasOwnProperty(k+'_position') === false && !pos) ||
                    (rep.hasOwnProperty(k+'_position') && pos === '1')
                )
            ) {
                ans.push(k);
            }
        });

        return ans;
    },

    _get_ans_progress: function(data)
    {
        var progress = {},
            ans = [];

        // add *records*
        ans = this._get_ans_progress_fake(ans, data.REP);
    
        // get answers total
        $.each(app.CACHE.REP_ANS, function(k,v){
            if( v.report_id === app.URI[1] ) {
                var identifier = ( v.id ) ? v.id : v.ts;
                ans.push(identifier);
            }
        });

        // add *records*
        ans = this._get_ans_progress_fake(ans, data.REP, '1');

        // get current question
        progress.no = ans.indexOf(app.URI[2]) + 1;

        // get next item
        progress.next = ans[progress.no];
        progress.prev = ans[progress.no - 2];

        // get totals
        progress.total = ans.length;
        progress.total_perc = (progress.no / ans.length * 100).toFixed(1).replace('.0','');

        // dont allow 100% for design purposes
        if( progress.total_perc === '100' ) {
            progress.total_perc = '98';
        }

        return progress;
    },

    _get_rep_que: function(data)
    {
        var q = {};

        if( app.URI[2] && app.CACHE.DEFAULT_QUESTIONS[app.URI[2]] ) {
            q = {
                'label': app.CACHE.DEFAULT_QUESTIONS[app.URI[2]].label,
                'description': app.CACHE.DEFAULT_QUESTIONS[app.URI[2]].description,
                'is_required': '1',
                'report_question_type_id': app.CACHE.DEFAULT_QUESTIONS[app.URI[2]].report_question_type_id
            };
        } else {
            q = this.get_row('rep_que', data.REP_ANS.report_question_id);
        }

        if(data.VEH_TYP.is_mileage === '1' && q.report_question_type_id === app.CACHE.DEFAULT_QUESTIONS.mileage.report_question_type_id && !q.answer_unit) {
            switch (data.VEH_TYP.is_mileage_type) {
                default:
                case 'mi':
                    q.answer_unit = 'Miles';
                    break;
                case 'km':
                    q.answer_unit = 'Kilometres';
                    break;
            }
        }

        return q;
    },

    _get_photos: function(row, count)
    {

        var pho = [],
            fields = this._get_photo_fields(count);

        $.each(fields, function(k,v){
            pho.push({
                'field': v,
                'file': ( row && (row[v] || row[v+'_local']) ) ? true : false,
                'local': ( row && (row[v]) ) ? false : true
            });
        });         

        return pho;
    },

    rep_ans: function(data)
    {
        // make data globally available
        app.DATA = {};
        app.DATA.REP = app.TPL.get_row('rep', app.URI[1]);

        // attach answer
        if( app.DATA.REP && app.URI[2] && app.CACHE.DEFAULT_QUESTIONS.hasOwnProperty(app.URI[2]) !== false ) {

            app.DATA.REP_ANS = {'is_completed': '0'};

            // add report answer for *records*
            if( app.DATA.REP[app.URI[2]] && app.DATA.REP[app.URI[2]] !== '' ) {
                app.DATA.REP_ANS.answer = app.DATA.REP[app.URI[2]];
                app.DATA.REP_ANS.is_completed = '1';
            }

            if( app.URI[2] === 'height' ) {
                app.DATA.REP_ANS.height_visibility = app.DATA.REP.height_visibility;
            }

        } else {
            app.DATA.REP_ANS = app.TPL.get_row('rep_ans', app.URI[2]);

            // force answers if missing
            if( app.DATA.REP.hasOwnProperty('id') ) {
                app.DATA.REP_ANS.is_completed = '1';
                
                // set fail status
                if( app.DATA.REP_ANS.hasOwnProperty('is_fail') === false ) {
                    app.DATA.REP_ANS.is_fail = '0';
                }
            }
        }

        // attach report
        data.REP = app.DATA.REP;

        // attach report answer
        if( app.DATA.REP_ANS ) {
            data.REP_ANS = app.DATA.REP_ANS;
            data.REP_ANS_PHO = this._get_photos(data.REP_ANS, 4);
            data.QUE_TYP = app.CACHE.QUE_TYP;
            data.QUE = app.TPL.get_row('rep_que', data.REP_ANS.report_question_id);
        }

        // get report related data
        data = this._get_report_data(data, app.URI[1]);

        // vehicle name
        data.VEH_NAME = this.getVehicleName(data.VEH_TYP, data.VEH);

        // report progress
        data.PROGRESS = this._get_ans_progress(data);

        // get question data
        data.REP_QUE = this._get_rep_que(data);

        if( data.REP_QUE.hasOwnProperty('id') ){
            if( data.REP_QUE.answer_unit && data.REP_QUE.answer_unit.indexOf(',') >= 0 ) {
                data.REP_QUE.answer_unit_formatted = data.REP_QUE.answer_unit.split(',');
            }
        }

        // global data
        app.DATA.REP_QUE = data.REP_QUE;
        
        return data;
    },

    /**
     * Check for photos from row
     * 
     * next = inc
     * prev = dec
     */
    check_nearest_photo: function(tbl, id, row, index, fields, action)
    {
        var nearest = false;

        // loop through field length
        $.each(fields, function(){

            if( action === 'inc') {
                index++;
            } else {
                index--;
            }

            // dont allow continue
            if( !fields[index] ) {
                return false;
            }

            // does file exist in answer
            if( row[fields[index]] || row[fields[index]+'_local'] ) {
                nearest = fields[index];
                return false;
            }
        });

        if( nearest === false ) {
            return false;
        }

        // URI prepending nearest file
        return tbl + '/' + id + '/' + nearest;
    },

    photo: function(data, tbl, id, field)
    {
        if( !tbl ) {
            tbl = app.URI[1];
            id = app.URI[2];
            field = app.URI[3];
        }

        // next/prev
        data.PROGRESS = {};
        // report answer
        data.PHOTO = app.TPL.get_row(tbl, id);

        var count = ( tbl === 'acc' ) ? 8 : 4;

        // photo fields
        var fields = this._get_photo_fields(count),
        // index of field
            index = fields.indexOf(field);

        if( index >= 0 ) {
            data.PROGRESS.next = this.check_nearest_photo(tbl, id, data.PHOTO, index, fields, 'inc');
            data.PROGRESS.prev = this.check_nearest_photo(tbl, id, data.PHOTO, index, fields, 'dec');
        }

        return data;
    },

    /**
     * Check answer needs photo or notes
     */
    check_ans_needs: function(f, rep_que, rep_ans)
    {
        var needs = false;

        if( f === 'answer' ) {

            if( rep_que.report_question_type_id === app.CACHE.QUE_TYP.NUMBER ||
                rep_que.report_question_type_id === app.CACHE.QUE_TYP.DECIMAL ||
                rep_que.report_question_type_id === app.CACHE.QUE_TYP.PERCENT
            ) {
                return true;
            }

            return needs;
        }

        // yes, always
        if( rep_que['is_required_'+f] === app.CACHE.PHOTO_REQ.YES ) {
            return true;
        }

        // yes, safety fail
        if( rep_que['is_required_'+f] === app.CACHE.PHOTO_REQ.YES_SAFETY_FAIL && rep_que.is_safety === '1' && rep_ans.is_fail === '1' ) {
            return true;
        }

        // yes, fail
        if( rep_que['is_required_'+f] === app.CACHE.PHOTO_REQ.YES_FAIL && rep_ans.is_fail === '1' ) {
            return true;
        }

        return needs;
    },

    /**
     * Check answer has photo
     */
    check_ans_has_photo: function(rep_ans)
    {
        var fields = this._get_photo_fields(4, true),
            pic = false;

        // loop each field to check for pictures
        $.each(fields, function(k,v){
            if( rep_ans.hasOwnProperty(v) ) {
                pic = true;
                return false;
            }
        });

        return pic;
    },

    check_ans_has_answer: function(ans)
    {
        if( ans.hasOwnProperty('answer') === false || ans.answer === '' ){
            return false;
        }

        return true;
    },

    get_rep_ans_fake: function(rep_ans, rep, url, pos)
    {
        $.each(app.CACHE.DEFAULT_QUESTIONS, function(k,v){
            if(
                (
                    (rep.id && rep[k] && rep[k] !== '0') ||
                    (!rep.id && rep.hasOwnProperty(k))
                ) && (
                    (rep.hasOwnProperty(k+'_position') === false && !pos) ||
                    (rep.hasOwnProperty(k+'_position') && pos === '1')
                )
            ){
                var row = {
                    'label': app.CACHE.DEFAULT_QUESTIONS[k].label,
                    'ts': k,
                    'slug': url + k
                };

                // fail
                if( rep[k] !== '' ){
                    row.is_completed = '1';
                    row.is_fail = '0';
                }

                rep_ans.push(row);
            }
        });

        return rep_ans;
    },

    get_rep_ans: function(report, report_id, answer)
    {
        var rep_ans = [],
            url = 'rep_ans/'+report_id+'/';

        // veh_typ.*records*
        if( report ) {
            rep_ans = this.get_rep_ans_fake(rep_ans, report, url);
        }

        // get answers from cache
        var i = 1;

        $.each(app.CACHE.REP_ANS, function(k,v){
            if( v.report_id === report_id ) {

                // attach extra data
                // get relevant question
                var que = app.TPL.get_row('rep_que', v.report_question_id);
                var ans =  $.extend({}, {
                    'slug': url,
                    'has_answer': app.TPL.check_ans_has_answer(v),
                    'has_photo': app.TPL.check_ans_has_photo(v),
                    'needs_photo': TPL.check_ans_needs('photo', que, v),
                    'has_notes': ( v.hasOwnProperty('notes') && v.notes !== '' ) ? true : false
                }, v);
                 
                if( v.id ) {
                    ans.slug += v.id;
                } else {
                    ans.slug += v.ts;
                }

                // completed (answer|photo|notes)
                if(
                    (ans.has_answer === false && app.TPL.check_ans_needs('answer', que, v) ) ||
                    (ans.has_photo === false && app.TPL.check_ans_needs('photo', que, v) ) ||
                    (ans.has_notes === false && app.TPL.check_ans_needs('notes', que, v) )
                ) {

                    delete ans.is_completed;

                } else if( v.hasOwnProperty('id') ) {

                    // completed if has id
                    ans.is_completed = '1';
                }

                // check for safety fail
                if( v.hasOwnProperty('is_fail') && que.is_safety === '1' ) {
                    ans.is_safety = '1';
                }

                ans.que = que;

                rep_ans.push(ans);
            }
        });

        // check any fake questions need adding at the end?
        if( report ) {
            rep_ans = this.get_rep_ans_fake(rep_ans, report, url, '1');
        }

        var i = 1;
        $.each(rep_ans, function(k,v){
            v.i = i++;
        });

        return rep_ans;
    },

    _get_rep_progress: function(data, report_id)
    {
        var slug = 'rep_ans/'+report_id+'/',
            progress = {'status': 'Ready to Complete'};

        // previous question for unsynced
        if( data.REP.hasOwnProperty('id') ) {
            progress.status = 'Completed';
        } else {

            // previous quest
            progress.prev = slug + data.REP_ANS[ data.REP_ANS.length -1].ts;

            // check for incomplete answers
            $.each(data.REP_ANS, function(k,v){
                const que = TPL.get_row('rep_que', v.report_question_id, 'id');

                if( v.hasOwnProperty('is_completed') === false || v.is_completed === '0' || v.hasOwnProperty('is_fail') === false ||
                    (
                        que && que.is_required_photo !== '0' &&
                        !isNaN(parseInt(que.photo_quantity)) &&
                        parseInt(que.photo_quantity) > 0 &&
                        v.hasOwnProperty('is_valid') && v.is_valid === false
                    )
                ) {
                    progress.status = 'Incomplete';
                    return false;
                }
            });

            // submit
            if( progress.status !== 'Incomplete' ) {
                if( data.REP.hasOwnProperty('date_end') ) {
                    progress.submit = true;
                    progress.status = 'Ready to Submit';
                } else {
                    progress.completed = true;
                }
            }
        }

        return progress;
    },

    // report overview
    rep: function(data)
    {
        data.REP =  app.TPL.get_row('rep', app.URI[1]);

        if( !data.REP ) {
            app.redirect('home');
            return false;
        }

        // get report related data
        data = this._get_report_data(data, app.URI[1]);

        // vehicle name
        data.VEH_NAME = this.getVehicleName(data.VEH_TYP, data.VEH);

        // get answers for report
        data.REP_ANS = this.get_rep_ans(data.REP, app.URI[1], true);

        // status of report
        data.PROGRESS = this._get_rep_progress(data, app.URI[1]);

        // reset app data
        app.DATA = {};
        app.DATA.REP = data.REP;
        app.DATA.PROGRESS = data.PROGRESS;

        // check rep_ans:other lookup
        if( data.REP.hasOwnProperty('id') && data.REP.user_id !== app.CACHE.USR.id ) {
            var countAns = 0;

            // check if answers exist
            $.each(data.REP_ANS, function(k,v){
                if( v.hasOwnProperty('id') ){
                    countAns++;
                }
            });

            if( !countAns ) {
                data.REP_ANS_AJAX = true;
                app.DATA.REP_ANS_AJAX = true;
            }
        }

        return data;
    },

    rep_success: function(data)
    {
        data.REP =  app.TPL.get_row('rep', app.URI[1]);

        if( !data.REP ) {
            app.redirect('home');
            return false;
        }

        // get report related data
        data = this._get_report_data(data, app.URI[1], false);

        // vehicle name
        data.VEH_NAME = this.getVehicleName(data.VEH_TYP, data.VEH);

        // report safety fail
        data.REP_FAIL = app.REPORT.is_safety_fail(app.URI[1]);

        return data;
    },

    // maintenance overview
    mai: function(data)
    {
        data.MAI =  app.TPL.get_row('mai', app.URI[1]);

        if( !data.MAI ) {
            app.redirect('home');
            return false;
        }

        // get report data
        data = this._get_maintenance_data(data, app.URI[1]);

        // vehicle name
        data.VEH_NAME = this.getVehicleName(data.VEH_TYP, data.VEH);

        // get answers for report
        data.MAI_ANS = this.get_mai_ans(data.MAI, app.URI[1], true);
         
        // status of report
        data.PROGRESS = this._get_mai_progress(data, app.URI[1]);

        // question types
        data.MAI_QUE_TYP = app.CACHE.MAI_QUE_TYP;

        // check rep_ans:other lookup
        if( data.MAI.hasOwnProperty('id') ) {
            var countAns = 0;

            // check if answers exist
            $.each(data.MAI_ANS, function(k,v){
                if( v.hasOwnProperty('id') ){
                    countAns++;
                }
            });

            if( !countAns ) {
                data.MAI_ANS_AJAX = true;
            }
        }

        // reset app data
        app.DATA = {};
        app.DATA.MAI = data.MAI;
        app.DATA.PROGRESS = data.PROGRESS;
        app.DATA.MAI_ANS_AJAX = data.MAI_ANS_AJAX;

        return data;
    },

    mai_success: function(data)
    {
        data.MAI =  app.TPL.get_row('mai', app.URI[1]);

        if( !data.MAI ) {
            app.redirect('home');
            return false;
        }

        // get report data
        data = this._get_maintenance_data(data, app.URI[1], false);

        // vehicle name
        data.VEH_NAME = this.getVehicleName(data.VEH_TYP, data.VEH);

        // report safety fail
        data.MAI_FAIL = app.MAINTENANCE.is_safety_fail(app.URI[1]);
        
        return data;
    },
    
    _get_acc_progress: (data) => {
        var progress = {
            'incomplete': true,
            'status': 'Ready to Complete'
        };
        
        if(data.RESULT.hasOwnProperty('id')) {
            progress.status = 'Completed';
            progress.incomplete = false;
        } else {
            if(data.RESULT.hasOwnProperty('is_completed') && data.RESULT.is_completed === '0') {
                progress.status = 'Incomplete';
                return progress;
            }
            
            progress.incomplete = false;
            
            if( data.RESULT.hasOwnProperty('date_end') ) {
                progress.submit = true;
                progress.status = 'Ready to Submit';
            } else {
                progress.completed = true;
            }
        }
        
        return progress;
    },

    _get_mai_progress: function(data, maintenance_id)
    {
        var progress = {
            'incomplete': true,
            'status': 'Ready to Complete'
        };

        // previous question for unsynced
        if( data.MAI.hasOwnProperty('id') ) {
            progress.status = 'Completed';
            progress.incomplete = false;
        } else {

            // check for incomplete answers
            $.each(data.MAI_ANS, function(k,v){

                if( v.hasOwnProperty('is_completed') === false || v.is_completed === '0' || ( v.que.is_health === '1' && v.hasOwnProperty('health') === false && v.hasOwnProperty('health') === false ) ) {
                    progress.status = 'Incomplete';
                    return false;
                }
            });

            // submit
            if( progress.status !== 'Incomplete' ) {

                progress.incomplete = false;

                if( data.MAI.hasOwnProperty('date_end') ) {
                    progress.submit = true;
                    progress.status = 'Ready to Submit';
                } else {
                    progress.completed = true;
                }
            }
        }

        return progress;
    },

    veh_form: function(data)
    {
        var tmp = this._get('veh_typ', 'name', 'asc'),
            veh_typ = [];

        // add operator specific ones first
        $.each(tmp, function(k,v){
            if( v.operator_id && v.operator_id !== '0' ){
                veh_typ.push(v);
            }
        });

        // add generic ones second
        $.each(tmp, function(k,v){
            if( v.operator_id && v.operator_id === '0' ){
                veh_typ.push(v);
            }
        });

        // types
        data.VEH_TYP = veh_typ;

        return data;
    },

    _check_rep_complete: function(rep, rep_ans)
    {
        var status = 'green';

        // complete
        if( rep.hasOwnProperty('id') === false ) {
            
            // incomplete
            if(
                (rep.hasOwnProperty('shared') && rep.shared === '') ||
                (rep.hasOwnProperty('fuel') && rep.fuel === '') ||
                (rep.hasOwnProperty('mileage') && rep.mileage === '') ||
                (rep.hasOwnProperty('hours') && rep.hours === '') ||
                (rep.hasOwnProperty('adblue') && rep.adblue === '') ||
                (rep.hasOwnProperty('height') && rep.height === '') ||
                rep.hasOwnProperty('date_end') === false
            ) {
                return 'grey';
            }
        }

        // check for fails
        if( !rep_ans ) {

            // get report identifier
            var report_id = ( rep.hasOwnProperty('id') ) ? rep.id : rep.ts;

            // LOOP ANSWERS
            if( rep.hasOwnProperty('id') === false ) {
                $.each(app.CACHE.REP_ANS, function(k,v){
                    // check correct report
                    if( v.report_id === report_id ) {
                        // incomplete
                        if( v.hasOwnProperty('is_completed') === false || v.is_completed === '0' ) {
                            status = 'grey';
                            return false;
                        }
                    }
                });
            }             

            // LOOP answers
            // continue checks for fails on completed
            if( status === 'green' ) {

                $.each(app.CACHE.REP_ANS, function(k,v){

                    // check correct report
                    if( v.report_id === report_id ) {
                        // incomplete
                        if( v.hasOwnProperty('is_fail') && v.is_fail === '1' && app.cache_get_prop('rep_que', v.report_question_id, 'is_safety') === '1' ) {
                            status = 'red';
                            return false;
                        }
                    }
                });

                if( status === 'red' ) {
                    return status;
                }

                $.each(app.CACHE.REP_ANS, function(k,v){

                    // check correct report
                    if( v.report_id === report_id ) {
                        // incomplete
                        if( v.hasOwnProperty('is_fail') && v.is_fail === '1' ) {
                            status = 'orange';
                            return false;
                        }
                    }
                });
            }
        }

        return status;
    },

    /**
     * Get the full name of vehicle from both vehicle and vehicle type
     */
    getVehicleName: function(veh_typ, veh)
    {
        if(!veh_typ) {
            return '';
        }

        var name = '';

        if( veh_typ.name ) {
            name = veh_typ.name;
        }

        name += ', ';

        if( veh.make ) {
            name += veh.make;
        }

        if( veh.name ) {
            name += ' '+veh.name;
        }

        if( app.CACHE.DEVICE.width < 360 && name.length >= 30 ) {
            return name.substring(0, 30)+ '...';
        }

        return name;
    },

    _get_rep_list_data: function()
    {   
        // determine which records to get
        var include = ( app.URI[1] === 'other') ? 'not' : true,
            rep_tmp = this._get('rep', 'date_start', 'desc', false, false, false, false, false, include),
            rep = [];

        // add vehicle data
        // add vehicle type data
        $.each(rep_tmp, function(k,v){

            // vehicle data
            var veh = app.TPL.get_row('veh', v.vehicle_id);             

            // vehicle type data
            if( veh ) {
                var veh_typ = app.TPL.get_row('veh_typ', veh.vehicle_type_id);
                v.name = app.TPL.getVehicleName(veh_typ, veh);
                v.id_name = ( veh.hasOwnProperty('reg') ) ? veh.reg : veh.serial;
                v.id_type = ( veh.hasOwnProperty('reg') ) ? 'id_reg' : 'id_serial';
                v.reg_country = ( veh.hasOwnProperty('reg_country') ) ? veh.reg_country : '';
            } else {

                console.warn('skipping row, no vehicle');

                // skip adding item to rows
                return true;
            }

            // slug
            if( app.URI[1] === 'other' && app.CACHE.USR.priv_other_reports === '0' ) {
                v.slug = 'rep_list/other';
            } else {
                v.slug = ( v.id ) ? 'rep/'+v.id : 'rep/'+v.ts;
            }             

            // answers
            v.status = app.TPL._check_rep_complete(v);

            // ymd
            if( v.date_start ) {
                v.ymd = moment(v.date_start).format('YYYY-MM-DD');                
            }

            // add new row to array
            rep.push(v);
        });

        // place reports in date container
        rep = this._get_date_container('YYYY-MM-DD', rep, 'date_start');

        return rep;
    },

    rep_list: function(data)
    {
        // n of days mai will be deleted after
        data.REP_INTERVAL = app.REP_INTERVAL;

        // list of reports
        data.REP = this._get_rep_list_data();

        // total reports
        data.REP_COUNT = 0;

        $.each(data.REP, function(key,mm){
            $.each(mm, function(k,v){
                data.REP_COUNT++;
            });
        });

        return data;
    },

    _check_mai_complete: function(mai, mai_ans)
    {
        var status = 'green';

        // complete
        if( mai.hasOwnProperty('id') === false ) {
            
            // incomplete
            if( mai.hasOwnProperty('date_end') === false ) {
                return 'grey';
            }
        }

        // check for fails
        if( !mai_ans ) {

            // get maintenance identifier
            var maintenance_id = ( mai.hasOwnProperty('id') ) ? mai.id : mai.ts;

            // LOOP ANSWERS for incomplete answers
            if( mai.hasOwnProperty('id') === false ) {
                $.each(app.CACHE.MAI_ANS, function(k,v){
                    // check correct maintenance
                    if( v.maintenance_id === maintenance_id ) {
                        // incomplete
                        if( v.hasOwnProperty('is_completed') === false || v.is_completed === '0' ) {
                            status = 'grey';
                            return false;
                        }
                    }
                });
            }             

            // LOOP answers
            // continue checks for fails on completed
            if( status === 'green' ) {

                $.each(app.CACHE.MAI_ANS, function(k,v){
                    // check correct maintenance
                    if( v.maintenance_id === maintenance_id ) {

                        // incomplete
                        if( v.hasOwnProperty('health') && v.health === app.CACHE.MAI_ANS_HEALTH.safety.id && v.is_resolved !== '1' ) {
                            status = 'red';
                            return false;
                        }
                    }
                });

                if( status === 'red' ) {
                    return status;
                }

                // check for orange
                $.each(app.CACHE.MAI_ANS, function(k,v){
                    // check correct maintenance
                    if( v.maintenance_id === maintenance_id ) {

                        // incomplete
                        if( v.hasOwnProperty('health') && v.health === app.CACHE.MAI_ANS_HEALTH.repair.id && v.is_resolved !== '1' ) {
                            status = 'orange';
                            return false;
                        }
                    }
                });
            }
        }

        return status;
    },

    _get_mai_list_data: function()
    {   
        // determine which records to get
        var include = ( app.URI[1] === 'other') ? 'not' : true,
            mai_tmp = this._get('mai', 'date_start', 'desc', false, false, false, false, false, include),
            mai = [];

        // add vehicle data
        // add vehicle type data
        $.each(mai_tmp, function(k,v){

            // vehicle data
            var veh = app.TPL.get_row('veh', v.vehicle_id);

            // vehicle type data
            if( veh ) {
                
                v.id_name = ( veh.hasOwnProperty('reg') ) ? veh.reg : veh.serial;
                v.id_type = ( veh.hasOwnProperty('reg') ) ? 'id_reg' : 'id_serial';
                v.reg_country = ( veh.hasOwnProperty('reg_country') ) ? veh.reg_country : '';
                v.mai_che = app.TPL.get_row('mai_che', v.maintenance_checklist_id);

                if( !v.mai_che ) {
                    v.name = app.TPL.getVehicleName(app.TPL.get_row('veh_typ', veh.vehicle_type_id), veh);
                }

            } else {

                console.warn('skipping row, no vehicle');
                 
                // skip adding item to rows
                return true;
            }

            // slug
            if( app.URI[1] === 'other' && app.CACHE.USR.priv_mai_other === '0' ) {
                v.slug = 'mai_list/other';
            } else {
                v.slug = ( v.id ) ? 'mai/'+v.id : 'mai/'+v.ts;
            }             

            // answers
            v.status = app.TPL._check_mai_complete(v);

            // ymd
            if( v.date_start ) {
                v.ymd = moment(v.date_start).format('YYYY-MM-DD');                
            }

            // add new row to array
            mai.push(v);
        });

        // place maiorts in date container
        mai = this._get_date_container('YYYY-MM-DD', mai, 'date_start');

        return mai;
    },

    mai_list: function(data)
    {
        // n of days mai will be deleted after
        data.MAI_INTERVAL = app.MAI_INTERVAL;

        // LIST of reports
        data.MAI = this._get_mai_list_data();

        // total reports
        data.MAI_COUNT = 0;

        $.each(data.MAI, function(key,mm){
            $.each(mm, function(k,v){
                data.MAI_COUNT++;
            });
        });

        return data;
    },
    
    defect_base_data: (data) => {
        if(app.URI[1]) {
            data.VEH = TPL.get_row('veh', app.URI[1]);
            data.VEH_TYP = TPL.get_row('veh_typ', data.VEH.vehicle_type_id);
            data.VEH_NAME = TPL.getVehicleName(data.VEH_TYP, data.VEH);
        }
        
        return data
    },
    
    defect_edit: (data) => {
        data = TPL.defect_base_data(data);
        if(app.CACHE.VEHICLE_DEFECT && app.URI[1]) {
            data.DEFECT = app.CACHE.VEHICLE_DEFECT;
        } else {
            data.DEFECT = [];
        }
        
        if(data.DEFECT.notes && data.DEFECT.notes.length === 0) {
            delete data.DEFECT.notes;
        }
        
        if(data.DEFECT.answer && data.DEFECT.answer.length === 0) {
            delete data.DEFECT.answer;
        }
        
        app.DATA = data;
        return data;
    },
    
    defect_view: (data) => {
        data = TPL.defect_base_data(data);
        if(app.CACHE.VEHICLE_DEFECT && app.URI[1]) {
            data.DEFECT = app.CACHE.VEHICLE_DEFECT;
        } else {
            data.DEFECT = {};
        }
        
        if(data.DEFECT.notes && data.DEFECT.notes.length === 0) {
            delete data.DEFECT.notes;
        }
        
        if(data.DEFECT.answer && data.DEFECT.answer.length === 0) {
            delete data.DEFECT.answer;
        }
        
        app.DATA = data;
        return data;
    },
    
    live_defects_list: (data) => {
        data = TPL.defect_base_data(data);
        app.DATA = data;
        return data;
    },

    admin: function(data)
    {
        data.STORAGE = app.CACHE.STORAGE;
        data.ONLINE = app.ONLINE;
        data.DEVICE = app.CACHE.DEVICE;
        data.WEBAPP = app.WEBAPP;
        data.TS = new Date(CONFIG.build_timestamp).toLocaleString();

        return data;
    },

    errors: function(data)
    {
        data.ERRORS = this._get('ERRORS', 'date_added', 'desc');

        return data;
    },

    _get_date_container: function(type, data, field)
    {
        // type
        var result = {};

        // default field if not passed
        if( field === false || field === undefined ) {
            field = 'date_added';
        }         

        // date format
        let format = type;
        if( type === 'month' ) {
            format = 'MMM Y';
        } else if( type === 'day' ) {
            format = 'ddd Do MMM, YYYY';
        }

        $.each(data, function(k,v){
            if(!v[field]) {
                return;
            }

            var date = ( type === 'month') ? moment(v[field].substr(0, 7)+'-01').format(format) : moment(v[field].substr(0, 10)).format(format);

            // create monthly container
            if( result.hasOwnProperty(date) === false ) {
                result[date] = [];
            }

            result[date].push(this);
        });

        return result;
    },

    _get_month_filter: function(result, field_date)
    {
        var months = {},
            month_filter = this._get(result, field_date, 'desc', 'YYYY-MM');

        // loop each month to english date
        $.each(month_filter, function(k,v){
            months[k] = moment(k+'-01').format('MMM YYYY');
        });

        return months;
    }
};