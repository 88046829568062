/** DO NOT MODIFY THIS FILE IS OVERWRITTEN VIA Saguaro CLI */
export const CONFIG = {
    "online": true,
    "debug": false,
    "demo": false,
    "webapp": true,
    "dev_url": "",
    "sitename": "assetgo",
    "sitename_uppercase": "AssetGo",
    "version": "4.4.7",
    "short_version": "447",
    "build_timestamp": 1733233505930,
    "testing": false,
    "staging": false,
    "sentry": true
};