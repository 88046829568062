import $ from 'jquery';
import Awesomplete from "awesomplete";
import Localforage from  'localforage';
import moment from "moment";
import * as Handlebars from 'handlebars';
import * as Sentry from '@sentry/capacitor';
import { browserProfilingIntegration, browserTracingIntegration, replayIntegration } from "@sentry/browser";

// Import Core functionality javascript
import { ActionSheetCore } from './core/action_sheet';
import { AJAX } from "./core/ajax";
import { CAMERA } from "./core/camera";
import { DATE } from "./core/date";
import { FILE } from "./core/file";
import { FILE_TRANSFER } from "./core/file_transfer";
import { FLASHLIGHT } from "./core/flashlight";
import { FORM } from "./core/form";
import { FUEL_EXPENSES } from "./core/fuel_expenses";
import { GEO } from "./core/geo";
import { MAINTENANCE } from "./core/maintenance";
import { NetworkCore } from "./core/network";
import { PUSH } from "./core/push";
import { QR } from "./core/qr";
import { REPORT } from "./core/report";
import { SYNC } from "./core/sync";
import { TIMEOUT } from "./core/timeout";
import { TPL } from "./core/tpl";
import { UPCOMING } from "./core/upcoming";
import { VEHICLES } from "./core/vehicles";
import { TABLES } from "./core/tables";
import { ROUTES } from "./core/routes";

import { VIEWS } from "./views/views.js";
import './plugins';
import './helpers';
import { Device } from "@capacitor/device";
import { Capacitor } from "@capacitor/core";
import { StatusBar } from "@capacitor/status-bar";
import { Dialog } from "@capacitor/dialog";
import { Browser } from "@capacitor/browser";
import { TextZoom } from "@capacitor/text-zoom";
import { Keyboard } from "@capacitor/keyboard";
import { App } from "@capacitor/app";
import { CONFIG } from "./config";
import { ActionSheetButtonStyle } from "@capacitor/action-sheet";
import { ModalCore } from "./core/modal";
import { AppUpdate } from "./core/app_update";

window.$ = $;
window.moment = moment;
window.Handlebars = Handlebars;
const localforage = Localforage;
window.app = {

    /**
     * Determines if app loads compiled handlebar templates
     * 
     * 1) check build.min.js in <body> is included
     * 2) check to see if local debug in <head>
     * 3) run gulpit.bat
     */
    
    // branding
    SITENAME: CONFIG.sitename,
    UPPERSITENAME: CONFIG.sitename_uppercase,
    //SITENAME: 'assetgo',
    //UPPERSITENAME: 'AssetGo',
    //SITENAME: 'logistics',
    //UPPERSITENAME: 'VR Centre Fleet',

    // template
    IP_DEV: CONFIG.dev_url,

    // are the URLs online
    ONLINE: CONFIG.online,

    // Desktop version
    WEBAPP: CONFIG.webapp,
    
    // app version information
    V: CONFIG.version,
    VERSION: CONFIG.version.replaceAll('.', ''),
    V_LETTER: '',

    // settings
    PAGE_COUNT: 0,
    PAGE_COUNT_MAX: 500,
    REP_INTERVAL: 28,
    MAI_INTERVAL: 21,

    // axles
    MIN_AXLES: 2,
    MAX_AXLES: 6,

    // timers
    TIMEOUT_AJAX: 30000,
    TIMEOUT_SYNC_GET: 15000,
    TIMEOUT_SYNC_SEND: 15000,
    BLANK_DATE: '0000-00-00',
    BLANK_DATETIME: '0000-00-00 00:00:00',

    // number of photos form/answer can submit
    PHOTOS: 4,

    // vehicle status
    VEH_STATUS: ['Active', 'Suspended', 'VOR', 'Out of Service', 'SORN', 'SOLD', 'Spare', 'Offline'],

    // animation between changing pages
    ANIMATION: { 'type': 'slideIn', 'right': 'Right', 'left': 'Left', 'end': '', 'speed': 601 },

    // encryption key has to be the same on the cms
    ENCRYPTION: 'RH<|-+R-V&hZY?]_4HJy<|v.ql!Iy>N&x|}tl%6DOx3UD#ABc>)EDzz,r#kVOU!sD-sZ#`Sqyv-3rk/U+',
    
    // app.HASH routes for app
    ROUTES: ROUTES,

    // cache tables
    TBL: TABLES,

    TEMPLATES: {},
    CACHE: {},
    DOM: {},
    DATA: {},
    HASH: '',
    URI: [],
    HISTORY: [],
    STEPS: [],
    VIEW: {},
    
    SENTRY_DATA: false,

    /**
     * Constructor function
     */
    init: async () => {
        // Due to this being important only for the initial load this is a core class designed to be loaded only once
        new AppUpdate();
        if(CONFIG.sentry) {
            app.sentryInit();
        }

        // Load core files
        app.load_core();
        await import('./templates.js');
        app.VIEW = VIEWS;

        if(!Capacitor.isNativePlatform()) {
            // PHONEGAP DISABLED
            app.PHONEGAP = false;
            await app.onDeviceReady();
        } else {
            // PHONEGAP ENABLED
            app.PHONEGAP = true;
            document.addEventListener('deviceready', app.onDeviceReady, false);
            await App.addListener('resume', app.onDeviceResume);
            await App.addListener('backButton', () =>  {
                app.allowed_redirect('back');
            });
            await App.addListener('appRestoredResult', app.appRestored);
            document.addEventListener('pause', app.onPause, false );
        }
    },

    sentryInit: () => {
        const USR = ( localStorage.getItem('usr') === null ) ? { settings: { logged_in: false } } : app.cache_check_type('usr');
        const subdomain = USR && USR.settings && USR.settings.demo ? 'demo' : 'cms';
        const url = ( app.ONLINE ) ? 'https://'+`${subdomain}.assetgo.co.uk/tunnel` : app.IP_DEV+'/tunnel';
        
        
        // Initialize Sentry information as soon as possible
        const sentryOptions = {
            dsn: "https://5f5d1f07f0ef1185b72f38180a589fb9@sentry.pnp.digital/3",
            release: "assetgo@" + app.V,
            dist: 'production',
            tunnel: url,
            integrations: [
                browserTracingIntegration(),
                browserProfilingIntegration(),
                replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                    networkCaptureBodies: true,
                }),
            ],
            enableTracing: true,
            tracesSampleRate: 0.1,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        }
        
        Sentry.init(sentryOptions);
    },

    onDeviceResume: async (event) => {
        var timeoutLength = 400;
        console.log(`Device resume:`, event);

        // hack for ios 13 camera hiding statusbar
        if( app.PHONEGAP && Capacitor.getPlatform() === 'ios' && app.CACHE && app.CACHE.DEVICE && app.CACHE.DEVICE.v >= 13 ) {
            await StatusBar.hide();
            await StatusBar.show();
        }

        // camera low memory check
        /*if( event && app.PHONEGAP && Capacitor.getPlatform() === 'android' && event.pendingResult && event.pendingResult.pluginServiceName.toLowerCase() === 'camera' && !window.location.hash ) {

            // Figure out whether or not the plugin call was successful and call
            // the relevant callback. For the camera plugin, "OK" means a
            // successful result and all other statuses mean error
            if( event.pendingResult.pluginStatus.toLowerCase() === "ok" ) {

                // loading state
                document.getElementById('content-load').innerHTML = '<div class="ac" style="padding:70px"><img src="img/loading-black.svg"></div>';

                var cameraState = JSON.parse(localStorage.getItem('cameraState'));

                setTimeout(function(){
                    app.CAMERA.win(event.pendingResult.result, cameraState);
                }, timeoutLength);

            } else {
                console.log('cameraCallback failure');
            }
        } else {*/
            setTimeout(function(){

                // app lockout checks
                app.TIMEOUT.check();

                // check flashlight state
                app.FLASHLIGHT.resume();

            }, timeoutLength);
        //}
    },
    
    appRestored: async (e) => {
        await app.setup_cache_synchronous();
        if(!e.data) {
            return;
        }
        
        if(!e.success) {
            console.log('Plugin call failed: ', e);
            return;
        }
        
        if(e.pluginId !== 'Camera') {
            console.warn(`Unable to handle appRestored for plugin ${e.pluginId}`);
            return;
        }
        
        const photoData = e.data;
        
        try {
            let cameraState = localStorage.getItem('cameraState');
            if(!cameraState) {
                return;
            }
            
            cameraState = JSON.parse(cameraState);
            const newFileUri = await FILE.move_picture(photoData, cameraState, true);
            
            let field = ( cameraState.field ) ? cameraState.field : cameraState.$img.attr('data'),
                data = {};
            data[field + '_local'] = newFileUri.filename;
            data['capacitor'] = true;
            data[field + '_needs_uploading'] = true;
            
            // save
            app.FORM.save_offline(cameraState.tbl, 'edit', data, true, cameraState.identifier, false, async () => {
                await app.redirectAsync(cameraState.page);
            }, true);
        } catch(err) {
            console.error(`Something went work recovering device: ${err.message}`);
        }
    },

    onPause: function()
    {
        console.warn('pause');
    },

    /**
     * Function called when device is ready
     */
    onDeviceReady: async () => {
        //const cspContent = $('meta[http-equiv]').attr('content');
        let bodyClasses = app.SITENAME;

        if( app.WEBAPP ) {
            bodyClasses += ' webapp';
        }

        $('body').addClass(bodyClasses);

        /*if( !cspContent ) {
            alert('Missing CSP <meta>');
        }*/

        // tell styling to tweak dom for webapp version
        if( app.WEBAPP ) {

            $('title').text(app.UPPERSITENAME);

            // check to see if environments are correct
            if(Capacitor.isNativePlatform()) {
                alert('WEBAPP cannot be loaded in cordova environment');
            }

            // favicon
            $('head').append(`<link href="https://cms.assetgo.co.uk/dist/img/${app.SITENAME}/fav.png" type="image/png" rel="shortcut icon">`);
    
        }

        // force statusbar to not overlap content
        await app.statusbar();

        app.NETWORK.start().catch((err) => console.log(`ERROR network plugin failed: ${err.message}`));

        // CACHE
        await app.setup_cache_asynchronous(true);
        
        if(CONFIG.sentry && !app.SENTRY_DATA) {
            Sentry.setExtra('cap_device', await Device.getInfo());
            Sentry.setExtra('cache_user', app.CACHE.USR);
            
            app.SENTRY_DATA = true;
        }
    },

    /**
     * Used to load all the core js files into the app object
     */
    load_core: () => {
        app.ACTION_SHEET = new ActionSheetCore();
        app.AJAX = AJAX;
        app.CAMERA = CAMERA;
        app.DATE = DATE;
        app.FILE = FILE;
        app.FILE_TRANSFER = FILE_TRANSFER;
        app.FLASHLIGHT = FLASHLIGHT;
        app.FORM = FORM;
        app.FUEL_EXPENSES = FUEL_EXPENSES;
        app.GEO = GEO;
        app.MAINTENANCE = MAINTENANCE;
        app.MODAL = new ModalCore();
        app.NETWORK = new NetworkCore();
        app.PUSH = PUSH;
        app.QR = QR;
        app.REPORT = REPORT;
        app.SYNC = SYNC;
        app.TIMEOUT = TIMEOUT;
        app.TPL = TPL;
        app.UPCOMING = UPCOMING;
        app.VEHICLES = VEHICLES;
    },

    statusbar: async () => {
        if(!app.PHONEGAP || !Capacitor.isPluginAvailable('StatusBar')) {
            return;
        }

        if(Capacitor.getPlatform() === 'android') {
            await StatusBar.setOverlaysWebView({ overlay: false });
            await StatusBar.setBackgroundColor({ color: '#111111' });
        }

        await StatusBar.show({
            animation: "NONE"
        });
    },

    /**
     * Called when localForage fully loaded
     */
    setup_cache_synchronous_ready: async () => {
        // CACHE
        await app.setup_cache_synchronous();

        // DOM
        await app.setup_dom();

        // EVENTS
        app.setup_events(true);

        // URI
        $(window).on('hashchange', app.hashchange);
        app.hashchange(true);
    },

    /**
     * Main Routing function
     */
    hashchange: function(init)
    {
        // URI/ROUTE/HASH/HASHCHANGE
        app.setup_routing(init);

        // document events
        app.setup_events(false);

        // load handlebars template
        app.TPL.action();
    },

    /**
     * Get view.js DOM element
     *
     * @param dom string
     */
    _: function(dom, set)
    {
        // return whole object
        if( !dom ) {
            return app.VIEW[app.HASH].DOM;            
        }

        // set dom element
        if( set ){
            app.VIEW[app.HASH].DOM[dom] = set;
            return;
        }

        // return dom object property
        return app.VIEW[app.HASH].DOM[dom];
    },

    /**
     * Determine if to open document in google docs viewer (browser)
     * 
     * @param string f 
     * @return string
     */
    browser_open_google_docs: function(f)
    {
        if(
            app.PHONEGAP && app.CACHE.DEVICE.platform === 'android' && (
                f.slice(f.length-4) === '.pdf' || 
                f.slice(f.length-4) === '.doc' || 
                f.slice(f.length-5) === '.docx' ||
                f.slice(f.length-4) === '.xls' || 
                f.slice(f.length-5) === '.xlsx'
            )
        ) {
            return true;
        }

        return false;
    },

    /**
     * Open link in a new tab
     *
     * @param string dom [element to find a.browser-open links]
     */
    browser_open: function()
    {
        // open in default app browser
        app.DOM.content_load.find('a.browser-open').on('click', async (e) => {

            // 1. prevent default
            e.preventDefault();

            console.log( 'loading external link...');

            var href = $(e.currentTarget).attr('href');

            // open .pdf in google doc viewer as android browser wont render pdfs :/
            var url = ( app.browser_open_google_docs(href) ) ? 'https://docs.google.com/gview?embedded=true&url='+encodeURI(href) : href;

            // 2. open phone browser
            await Browser.open({
                url: url,
                windowName: '_blank'
            });
        });
    },

    check_json: function(str)
    {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }

        return true;
    },

    check_connection: function()
    {
        return app.NETWORK.STATUS;
    },

    /**
     * Get default list 
     * @return {[type]} [description]
     */
    get_veh: function()
    {
        var veh = app.TPL._get('VEH');

        // limit user to specific depot if setting is enabled
        if( app.CACHE.USR.priv_opr_dep_restrict === '1' && (
                ( app.CACHE.USR.operator_depot_id && app.CACHE.USR.operator_depot_id !== '0' ) ||
                ( app.CACHE.USR.operator_depot_ids && Object.keys(app.CACHE.USR.operator_depot_ids).length > 0 ) 
            )
        ) {
            veh = veh.filter(function(r){
                if( r.operator_depot_id === app.CACHE.USR.operator_depot_id) {
                    return r;
                }
                
                if(app.CACHE.USR.operator_depot_ids && app.CACHE.USR.operator_depot_ids[r.operator_depot_id]) {
                    return r;
                } else if(typeof app.CACHE.USR.operator_depot_ids === "string" && r.operator_depot_id === app.CACHE.USR.operator_depot_ids) {
                    return r;
                }
            });
        }

        return veh;
    },

	/**
	 * Enable autocomplete to attach portfolios to contact
	 */
     setup_autocomplete: function()
     {
        // remove any previous listeners
        window.removeEventListener("awesomplete-selectcomplete", app.VIEW[app.HASH].selectAutocomplete, false);
        
        // get 3 different types of results for dropdowns
        app.get_veh_autocomplete();
        app.get_mai_autocomplete();

        for(let i = 0; i < app.VIEW[app.HASH].TYPES.length; i++){
            if(!app.VIEW[app.HASH].DOM.autocomplete_input[i]) {
                continue;
            }
            
            app.VIEW[app.HASH]['awesomplete_'+app.VIEW[app.HASH].TYPES[i]] = new Awesomplete(app.VIEW[app.HASH].DOM.autocomplete_input[i], {
                list: app.VIEW[app.HASH].DATA[app.VIEW[app.HASH].TYPES[i]],
                minChars: app.HASH === 'mai' ? 0 : 1,
                maxItems: 25
            });
            $(app.VIEW[app.HASH].DOM.autocomplete_input[i]).attr('data-autocomplete', `awesomplete_${app.VIEW[app.HASH].TYPES[i]}`);
        }
 
 
        // SELECT
        window.addEventListener("awesomplete-selectcomplete", app.VIEW[app.HASH].selectAutocomplete, false);
 
        // NUMBER PLATE DETECTION
        app.setup_ocr();

        // NUMBER PLATE DETECTION
        app.setup_barcode();
     },
    
    get_mai_autocomplete: () => {
        if(!app.URI[1]) {
            return;
        }
        
        const mai = app.TPL.get_row('mai', app.URI[1]);
        
        if(!mai) {
            return;
        }
        
        const mai_que = app.CACHE.MAI_QUE.filter(que => que.maintenance_checklist_id === mai.maintenance_checklist_id && que.choices);
        if(!app.VIEW[app.HASH].DATA) {
            app.view[app.HASH].DATA = {};
        }
        
        mai_que.forEach(que => {
            if(que.is_deleted === '1') {
                return;
            }
            
            if(que.type === app.CACHE.MAI_QUE_TYP.radio_custom && que.choices && que.choices.split(',').length > 5) {
                const choices = [];
                que.choices.split(',').forEach((choice) => choices.push({ label: choice, value: choice }));
                app.VIEW[app.HASH].DATA[que.id] = choices;
                if(!app.VIEW[app.HASH].TYPES.includes(que.id)) {
                    app.VIEW[app.HASH].TYPES.push(que.id);
                }
            }
        });
        
        console.log(mai_que);
    },

    /**
     * Get value from scanned barcode
     */
    check_barcode: function(val)
    {
        // do nothing with no serial
        if( !val ) {
            app.show_alert('There is no value on this barcode');
            return;
        }
        
        function cleanRegOrSerial(s)
        {
            if( !s ) {
                return '';
            }

            return s.trim().toLowerCase().replace(/\s+/g, '');
        }

        // cleanup
        const valClean = cleanRegOrSerial(val);

        const vehicles = app.CACHE.VEH.filter(function(r){
            if( cleanRegOrSerial(r.serial) === valClean || cleanRegOrSerial(r.reg) === valClean || r.fleet.toLowerCase() === val.toLowerCase() ) {
                return r;
            }
        });


        // try to select vehicle
        if( vehicles.length ) {
            
            if( vehicles.length === 1 ) {
                app.VIEW[app.HASH].selectAutocomplete(vehicles[0].id);
            } else {
                let scannedResultsList = '';
                
                // create list of results
                $.each(vehicles, function(k,vehicle){
                    let number = k+1;
                    scannedResultsList += `
                    <div class="result">
                        ${number}:
                        ${Handlebars.helpers.regSerial(vehicle, true, 'span', false)}
                    </div>`;
                });

                // paint results
                app._('barcode_results').html(scannedResultsList).parent().show();

                // redirect
                app._('barcode_results').find('.result').on('click', function(){
                    const id = $(this).find('span').attr('data');

                    app.VIEW[app.HASH].selectAutocomplete(id);
                });
            }

        } else {
            app.show_alert(`Could not find Asset with serial/reg/fleet no: ${val}`);
        }
    },
    
    /**
     * Add barcode button to DOM
     * Add click event to button
     * Setup cordova plugin/desktop
     */
    setup_barcode: function()
    {
        if( app.CACHE.USR.opr_priv_barcode !== '1' || app.WEBAPP ) {
            return;
        }

        const html = `
        <div id="barcode-container" class="id-name-container">
            <p class="p-b-n"><label for="serial">QR/Barcode:</label></p>
            <a id="btn-barcode" class="btn-camera">
                <i class="fa fa-barcode"></i>
                <i class="fa fa-spin fa-spinner"></i>
                Scan
            </a>
            <div id="barcode-results-container" style="display:none">
                <h2>Scan returned multiple results, please select asset:</h2>
                <div id="barcode-results"></div>
            </div>
        </div>`;

        // add button to dom
        app.DOM.content.find('#serial-container').after(html);

        app.VIEW[app.HASH].DOM.btn_barcode = app.DOM.content_load.find('#btn-barcode');
        app.VIEW[app.HASH].DOM.barcode_results = app.DOM.content_load.find('#barcode-results');

        // CLICK
        app._('btn_barcode').on('click', async () => {

			// dont proceed
			if( $(this).hasClass('loading') ) {
				return;
			}

			// change loading state
			$(this).addClass('loading');

			if( app.PHONEGAP ) {
                try {
                    await app.QR.start();
                    const result = await app.QR.startScan({
                        cameraDirection: 'back'
                    });
                    app.check_barcode(result);
                } catch(err) {
                    console.log(err);
                    await app.QR.stopScan();
                    app.show_alert("Scanning failed: " + err, app.t('generic_attention'));
                }
			} else {
                app.check_barcode( prompt("Enter the Serial/Reg/Fleet No", "12345678") )
            }

            $(this).removeClass('loading');
        });
    },
    
    /**
     * Optical character recognition - via camera for registration lookup
     */
    setup_ocr: function()
	{
        if( app.CACHE.USR.opr_priv_ocr !== '1' || app.WEBAPP ) {
            return;
        }

        // add button to dom
        app.DOM.content.find('#reg-container').append('<a id="btn-ocr" class="btn-camera"><i class="fa fa-camera"></i><i class="fa fa-spin fa-spinner"></i></a>');
        
        // cache DOM
		app.VIEW[app.HASH].DOM.btn_ocr = app.DOM.content_load.find('#btn-ocr');

		// https://www.npmjs.com/package/cordova-plugin-mobile-ocr#plugin-usage
		var sourceType = 0; // NORMFILEURI - very accurate

		app._('btn_ocr').on('click', function(){

			// dont proceed
			if( $(this).hasClass('loading') ) {
				return;
			}

			// change loading state
			$(this).addClass('loading');

			if( app.PHONEGAP ) {

                app.FLASHLIGHT.off();

				// take picture
				navigator.camera.getPicture(onSuccessPic, onFailPic, { quality: 100, correctOrientation: true });
			} else {
				var reg = prompt("Enter Reg", "AA40 AAA,GB,Mercedes Benz of Poole");

				if( reg ) {
					onSuccessOCR({
						foundText: ( reg ) ? true : false,
						blocks: {blocktext: reg.split(',')},
						lines: {linetext: reg.split(',')},
						words: {wordtext: reg.split(',')}
					});
				} else {
					onFailOCR('No registration entered');
				}
			}

            function reset()
            {
                // IOS HACK
                app.onDeviceResume(false);

                // Change button state
				app._('btn_ocr').removeClass('loading');
            }
             
			function onSuccessPic(imageData) {
				mltext.getText(onSuccessOCR, onFailOCR, {'imgType': 0, 'imgSrc': imageData});
			};
			
			function onFailPic(message) {
				alert('Failed because: ' + message);
                reset();
			};

            /**
             * Clean unwanted characters from vehicle registration
             * 
             * @param string reg 
             * @return string
             */
            function cleanReg(reg, ocr)
            {
                var regNew = reg.toLowerCase().replace(/\s/g, '').replace(/\i/g,'1').replace(/\o/g,'0').replace(/[^A-Za-z0-9]/g, '');

                // check to see if first 2 chars are GB
                // only for when we check ocr reg
                if( ocr ){
                    var regionCodes = ['gb','eu'];

                    // check to see if regioncode matched
                    if( regNew.length === 9 && regionCodes.indexOf(regNew.substr(0,2)) >= 0 ){
                        regNew = regNew.substr(2);
                    }
                }

                return regNew;
            }

			function onSuccessOCR(r){

				console.log('onSuccessOCR', r);

				if( r.foundText && r.blocks.blocktext[0] ) {

					// clean up for comparison
					var reg = cleanReg(r.blocks.blocktext[0], true);

					// loop through vehicles and try find a match
					var veh = app.CACHE.VEH.find(function(r){
						if( r.reg && cleanReg(r.reg) === reg ) {
							return r.id;
						}
					});

					// try to select vehicle
					if( veh && veh.id ) {
						app.VIEW[app.HASH].selectAutocomplete(veh.id);
					} else {
						app.show_alert('Could not find Asset with registration '+reg.toUpperCase(), 'Please try again');
					}

				} else {
					app.show_alert('Could not find any characters from image', 'Please try again');
				}

				reset();
			};
			
			function onFailOCR(message) {
				alert('Failed because: ' + message);
				reset();
			};
		});
	},

    /**
     * Get list of vehicles user has access too
     */
    get_veh_autocomplete: function()
    {
        var veh = this.get_veh(),
            data = ( app.URI[1] === 'trailer') ? {'all':veh, 'serial': []} : {'all':veh, 'reg': [], 'serial': []};

        // get full vehicle name
        var getLabel = function(r)
        {
            var str = '';

            if( r.hasOwnProperty('reg') && r.reg ) {
                str += r.reg.toUpperCase().replace(/ /g,'');
            } else {
                str += r.serial.replace(/ /g,'');
            }

            if( r.hasOwnProperty('make') ) {
                str += ' - ' +r.make;
            }

            if( r.hasOwnProperty('name') ) {
                str += ' ' + r.name;
            }

            return str;
        };

        // registration
        if( app.URI[1] !== 'trailer' ) {
            veh.filter(function(r){
                if( r.hasOwnProperty('reg') && r.reg ) {
                    data.reg.push({'label': getLabel(r), 'value': r.id});
                }
            });
        }

        // serial
        veh.filter(function(r){

            var veh_typ = false;

            // check can cannot tow trailers
            if( app.URI[1] === 'trailer' ) {
                veh_typ = app.TPL.get_row('veh_typ', r.vehicle_type_id);                
            }
             
            // make sure no towable items in this list if trailer searching
            if( r.hasOwnProperty('serial') && r.serial && ( veh_typ === false || (veh_typ && veh_typ.is_trailer === '1' ) ) ) {
                data.serial.push({'label': getLabel(r), 'value': r.id, test: true});
            }
        });

        app.VIEW[app.HASH].DATA = data;
    },

    /**
     * Check that there is no ajax call currently in progress
     */
    check_ajax: function()
    {
        if(
            app.FILE.AJAX ||
            app.SYNC.AJAX ||
            app.FORM.AJAX
        ) {
            return true;
        }

        return false;
    },

    /**
     * Check how many times pages changes
     *
     * If reached certain amount refresh
     */
    check_app_memory: function()
    {
        // if we have hashchange has begun
        if( app.HASHCHANGE === true ) {

            // increase page count
            app.PAGE_COUNT = app.PAGE_COUNT + 1;

            if( app.PAGE_COUNT === app.PAGE_COUNT_MAX ) {
                app.redirect();
            }
        }
    },

    check_user_login: function()
    {
        var loggedIn = 'user-logged-in',
            loggedOut = 'user-logged-out';

        if( app.CACHE.USR.settings && app.CACHE.USR.settings.logged_in === true && app.CACHE.USR.settings.sitename && app.CACHE.USR.settings.sitename === app.CACHE.SITENAME ) {
            $('body').addClass(loggedIn).removeClass(loggedOut);
        } else {
            $('body').addClass(loggedOut).removeClass(loggedIn);
        }
    },

    check_user_account: function(json)
    {
        var change = false,
            fields = ['is_deleted', 'is_suspended'];

        // LOOP each fields
        $.each(fields, function(k,v){ 

            if( json.hasOwnProperty(v) ) {
                change = true;
                app.CACHE.USR[v] = '1';
            } else if( app.CACHE.USR[v] === '1' ) {
                change = true;
                app.CACHE.USR[v] = '0';
            }
        });

        // update cache
        if( change ){
            app.cache_save('usr');
        }
    },

    /**
     * Check Vehicle is Suspended if so update DOM with text
     */
    check_veh_is_suspended: function(tbl, hash, vehicle_id, trailer, date_end)
    {
        var veh = app.TPL.get_row('veh', vehicle_id),
            dom_key = ( trailer ) ? 'is_suspended_trailer' : 'is_suspended',
            dom_elem = '#'+ dom_key.replace(/_/g, '-'),
            asset = ( trailer ) ? 'Trailer' : 'Asset',
            identifier = ( trailer ) ? app.URI[2] : app.URI[1],
            identifier_linked = false,
            $upcoming = ( trailer ) ? app.DOM.content_load.find('.upcoming-container[data="trailer"]') : app.DOM.content_load.find('.upcoming-container[data="main"]');

        // send linked report timestamp to delete if VOR
        if( tbl === 'rep' && app.URI[2] ) {
            identifier_linked = ( trailer ) ? app.URI[1] : app.URI[2];
        }

        // do nothing
        if( !veh || !veh.is_suspended || date_end ) {
            let $report_warning = ( trailer ) ? app._('report_warning_trailer') : app._('report_warning');
            $report_warning.hide();
            return;
        }

        // cache dom element
        app.VIEW[hash].DOM[dom_key] = app.DOM.content.find(dom_elem);

        // current state message
        if( veh.is_suspended !== '0' && veh.is_suspended !== '6' ) {

            // vehicle state
            var state = app.VEH_STATUS[veh.is_suspended];

            if( !state ){
                state = 'VOR';
            }
        
            var html = `<p class='f-18 b'>This ${asset} is currently marked as ${state}</p>`;

            // detect if we can add rep based on user setting
            if( tbl === 'rep' ) {

                html += `<p>You cannot create a report for this ${asset} as of yet.</p>`+
                `<p>Please check the ${asset} is safe to operate before operating</p>`;

                // change next button state
                app._('btn_next').removeClass('button-green').addClass('button-grey');
            }

            app.VIEW[hash].DOM[dom_key].html(html).show();
        }

        // double check with ajax lookup
        app.AJAX.veh_is_suspended(tbl, hash, veh, identifier, trailer, identifier_linked, $upcoming);
    },

    /**
     * Setup Footer Buttons + Events
     */
    setup_footer: function(opts)
    {
        // show footer
        app.DOM.footer.css('display', 'flex').removeClass('loading');

        // unbind any previous events
        app.DOM.footer_btn_left.unbind().html('').removeAttr('href').hide();
        app.DOM.footer_btn_right.unbind().html('').removeAttr('href').hide().removeClass('button-grey');

        $.each(opts, function(k,r){

            var $btn = app.DOM['footer_btn_'+r.btn];

            // show button
            $btn.show();

            // show text
            $btn.html(r.txt);

            if( r.hasOwnProperty('href') ) {
                if(r.href === 'fue_exp_setup') {
                    r.href = 'fue_exp_start';
                }

                if(typeof app.TEMPLATES[r.href] === "undefined") {
                    return;
                }

                $btn.attr('href', '#'+r.href);

             } else {

                // attach click to button
                $btn.on('click', function(){
                     
                    // click attribute element
                    if( r.hasOwnProperty('click') && app.VIEW[app.HASH].DOM[r.click] ) {
                        app._(r.click).trigger('click');
                    }
                });
            }
        });
    },

    // vanillaJS
    isJSON: function(str)
    {
        try {
            return (JSON.parse(str) && !!str);
        } catch (e) {
            return false;
        }
    },

    /**
     * Get full api url
     *
     * Replace special values
     *
     * [URI[]]
     *
     * @param  string url
     * @return string
     */
    get_api_url: function(url)
    {
        // PREPEND WITH API URL
        url = app.CACHE.URL.api + url;

        // SET :ID ON URL
        if( app.URI.length > 1) {
            url = url.replace('[URI[1]]', app.URI[1]);
        }

        return encodeURI(url);
    },

    /**
     * Merge form data and cache data
     */
    get_api_data: function(data)
    {
        var info = {
            'app': '1',
            'encryption_key': app.ENCRYPTION,
            'app_version': app.V.replaceAll('.', ''),
            'usr': {},
            'webapp': ( app.WEBAPP ) ? '1' : '0'
        };
         

        // replace data if empty
        if( data === undefined ) {
            data = {};
        }

        // user logged in credentials
        if( app.CACHE.USR.settings && app.CACHE.USR.settings.logged_in ) {
            info.usr.id = app.CACHE.USR.id;
            info.usr.email = app.CACHE.USR.email;
            info.usr.pin_token = app.CACHE.USR.pin_token;
            info.usr.date_updated = app.CACHE.USR.date_updated;
            info.usr.operator_date_updated = app.CACHE.USR.operator_date_updated;
        }

        return $.extend({}, data, info);
    },

    /**
     * Dont send user credentials in array but seperately
     */
    get_api_data_inline: function(data)
    {
        if( data === undefined ) {
            data = {};
        }
        
        delete data.usr;

        // app credentials
        data.app = '1';
        data.app_version = app.V.replaceAll('.', '');
        data.encryption_key = app.ENCRYPTION;

        // user crentials
        data.usrId = app.CACHE.USR.id;
        data.usrEmail = app.CACHE.USR.email;
        data.usrPin = app.CACHE.USR.pin_token;

        return data;
    },

    // get array of tables
    setup_cache_asynchronous_tables: function()
    {
        // array tables to get data from
        var tbls = [];

        $.each(app.TBL, function(k,v){
            if( v.hasOwnProperty('localforage') ) {
                tbls.push(k);
            }
        });

        return tbls;
    },

    // local forage is asynchronous so cant just attach to c object
    setup_cache_asynchronous: function(init)
    {
        localforage.config({
            driver: [localforage.INDEXEDDB, localforage.WEBSQL],
            storeName: 'app'
        });

        // get tables to retrieve data for
        var tbls = this.setup_cache_asynchronous_tables(),
            tbls_saved = [];

        // loop tables to get data from and create promise
        // so we cant start app until all data is ready
        $.each(tbls, async (k,tbl) => {
            try {
                await localforage.getItem(tbl, async (err, val) => {

                    if (err) {
                        console.warn(err);
                        return;
                    }

                    // save to CACHE
                    app.CACHE[tbl.toUpperCase()] = (val === null) ? [] : val;

                    // check to see if we need to start normal cache
                    tbls_saved.push(tbl);

                    // all loaded
                    if (tbls.length === tbls_saved.length) {

                        if (init === true) {
                            await app.setup_cache_synchronous_ready();
                        } else {
                            await app.setup_cache_synchronous();
                        }
                    }
                });
            } catch(err) {
                console.warn(err);
            }
        });
    },

    getDefaultQuestions: function(questionTypes)
    {
        return {
            // added in v2.1.8
            'shared': {
                label: 'Serial Number',
                description: 'Please enter the Serial Number for this Asset',
                report_question_type_id: questionTypes.NORMAL ?? '0'
            },
            'fuel': {
                label: 'Fuel/Charge Level',
                description: 'Please enter the fuel/charge level as accurately as possible.',
                report_question_type_id: questionTypes.PERCENT ?? '2'
            },
            'mileage': {
                label: 'Odometer Reading',
                description: 'Please enter the odometer reading for this asset.',
                report_question_type_id: questionTypes.NUMBER ?? '1'
            },
            'hours': {
                label: 'Hours of Operation',
                description: 'Please enter the hours of operation for this asset.',
                report_question_type_id: questionTypes.DECIMAL ?? questionTypes.NUMBER
            },
            'adblue': {
                label: 'AdBlue',
                description: 'Please enter the AdBlue level as accurately as possible.',
                report_question_type_id: questionTypes.PERCENT ?? '2'
            },
            'height': {
                label: 'Asset Height',
                description: 'Have you set the Height marker for this Asset?',
                report_question_type_id: questionTypes.HEIGHT ?? '3'
            }
        };
    },

    get_device: async () => {
        // device object
        var d = {
            width: $(window).width(),
            height: $(window).height()
        };

        // pixel ratios
        if( window.hasOwnProperty('devicePixelRatio') ) {
            d.pixelRatio = window.devicePixelRatio;
            d.widthFull = d.width * d.pixelRatio;
        }

        // environment settings
        if( app.PHONEGAP ) {
            const device = await Device.getInfo();
            d.version = device.osVersion;
            d.v = parseFloat(d.version);
            d.platform = device.platform;
        } else {
            d.platform = 'desktop';
            d.version = navigator.userAgent;
        }

        return d;
    },

    /**
     * Setup app.CACHE [localStorage]
     */
    setup_cache_synchronous: async () => {
        let cache = {};

        // OBJECTS: CACHE
        cache.USR = ( localStorage.getItem('usr') === null ) ? { settings: { logged_in: false } } : app.cache_check_type('usr');
        cache.RES = ( localStorage.getItem('res') === null ) ? [] : app.cache_check_type('res');
        cache.USR_TYP = ( localStorage.getItem('usr_typ') === null ) ? {} : app.cache_check_type('usr_typ');
        cache.QUE_TYP = ( localStorage.getItem('que_typ') === null ) ? {} : app.cache_check_type('que_typ');
        cache.MAI_ANS_HEALTH = ( localStorage.getItem('mai_ans_health') === null ) ? {} : app.cache_check_type('mai_ans_health');
        cache.MAI_QUE_TYP = ( localStorage.getItem('mai_que_typ') === null ) ? {} : app.cache_check_type('mai_que_typ');
        cache.URL_FILES = ( localStorage.getItem('url_files') === null ) ? {} : app.cache_check_type('url_files');
        cache.PHOTO_REQ = ( localStorage.getItem('photo_req') === null ) ? {} : app.cache_check_type('photo_req');
        cache.STORAGE = ( localStorage.getItem('storage') === null ) ? { setup: false, container: `.${app.SITENAME}`} : app.cache_check_type('storage');
        cache.VEH_UPCOMING = {};
        cache.FUE_EXP_TYPES = ( localStorage.getItem('fue_exp_types') === null ) ? [] : app.cache_check_type('fue_exp_types');
        cache.FUE_EXP_SOURCES = ( localStorage.getItem('fue_exp_sources') === null ) ? [] : app.cache_check_type('fue_exp_sources');
        cache.VEHICLE_DEFECT = (localStorage.getItem('vehicle_defect') === null) ? {} : app.cache_check_type('vehicle_defect');

        // which URL does the app use
        var subdomain = cache.USR && cache.USR.settings && cache.USR.settings.demo ? 'demo' : 'cms',
            url = ( app.ONLINE ) ? 'https://'+`${subdomain}.assetgo.co.uk/` : app.IP_DEV+'/';

        // app identifier for login
        cache.SITENAME = app.SITENAME;

        // get url if changed
        if( localStorage.getItem('url') === null ) {
            cache.URL = {
                'domain': url,
                'api': url + 'api/',
                'uploads': url + 'uploads/'
            };
        } else {
            cache.URL = app.cache_check_type('url');
        }
        
        if(cache.URL.domain !== url) {
            cache.URL = {
                'domain': url,
                'api': url + 'api/',
                'uploads': url + 'uploads/'
            };
        }

        // ajax cache setting
        cache.CACHE_AJAX = true;
        cache.DEVICE = await app.get_device();
        cache.CON_ERROR = ['No network connection', 'Cell generic connection', 'unknown',''];

        // ARRAY: CACHE
        cache.ERRORS = ( localStorage.getItem('errors') === null ) ? [] : app.cache_check_type('errors');
        cache.DEFAULT_QUESTIONS = app.getDefaultQuestions(cache.QUE_TYP);
        cache.REP_CHE = ( localStorage.getItem('rep_che') === null ) ? [] : app.cache_check_type('rep_che');
        cache.MAI_CHE = ( localStorage.getItem('mai_che') === null ) ? [] : app.cache_check_type('mai_che');
        cache.VEH_TYP = ( localStorage.getItem('veh_typ') === null ) ? [] : app.cache_check_type('veh_typ');

        // merge data
        app.CACHE = $.extend({}, app.CACHE, cache);

        app.CACHE.STORAGE.path = await app.FILE.get_full_path('', true);
        app.cache_save('storage');
    },

    /**
     * Check if cache has saved any data that is not a string
     */
    cache_check_type: function(prop)
    {
        var data = localStorage.getItem(prop);

        return typeof data === 'string' ? JSON.parse(data) : data;
    },

    body_css: async () => {
        let body = "";

        if( app.PHONEGAP ) {
            const deviceInfo = await Device.getInfo();
            $('body').addClass('phonegap platform-' + deviceInfo.platform.toLowerCase() + ' ' + 'version-' + deviceInfo.osVersion.replace(/\./g, '-'));
        }

        if( app.CACHE.USR.settings && app.CACHE.USR.settings.demo ) {
            body += ' user-demo';
        }

        // body classes
        $('body').addClass(body);
    },

    /**
     * Return objet of main DOM items
     * @return obj
     */
    setup_dom: async () => {
        await app.body_css();

        // FASTCLICK
        // if( app.PHONEGAP) {
        //     app.FastClick = FastClick.attach(document.body);
        // }

        // header
        app.DOM.branding = $('#branding');
        app.DOM.header = $('#header');
        app.DOM.footer = $('#footer');
        app.DOM.footer_centre = app.DOM.footer.find('#footer-centre');
        app.DOM.footer_btn_left = app.DOM.footer.find('#button-left');
        app.DOM.footer_btn_right = app.DOM.footer.find('#button-right');
        app.DOM.header_logo = app.DOM.header.find('#logo');
        app.DOM.header_sync = app.DOM.header.find('#btn-sync');
        app.DOM.header_btn_bk = app.DOM.header.find('#btn-bk');
        app.DOM.header_btn_flashlight = app.DOM.header.find('#btn-flashlight');
        app.DOM.header_btn_home = app.DOM.header.find('#btn-home');
        app.DOM.header_btn_msg = app.DOM.header.find('#btn-msg');
        app.DOM.header_btn_msg_span = app.DOM.header_btn_msg.find('span');
        app.DOM.header_title = app.DOM.header.find('#header-title');
        //app.DOM.header_scroll = app.DOM.header.find('#scroll-bar');

        // content
        app.DOM.content = $("div#content");
        app.DOM.content_load = app.DOM.content.find('div#content-load');
    },

    hack_fix: function($el, options)
    {
        var delay = ( options && options.delay ) ? options.delay : app.ANIMATION.speed + 150;

        // hide
        $el.hide();

        // show
        setTimeout(function(){ $el.show(); }, delay);
    },

    setup_branding: function()
    {
        // shorthand branding
        var b = app.CACHE.RES[0];

        // Clear any previous css
        if( app.HASH === 'logout' || !b || !b.branding ) {

            // reset css
            app.DOM.branding.html('');

            // reset logo
            if( app.SITENAME === 'assetgo' ) {
                app.DOM.header_logo.attr('src', app.DOM.header_logo.attr('data-src'));

            } else {
                app.DOM.header_logo.attr('src', `img/${app.SITENAME}/logo.png`);
            }

            return;
        }

        // Setup colours
        if( b.colour ) {

            // css rules
            var css = ''+
            '#html body #content a.button-primary {background: $primary; background: -webkit-linear-gradient(left top, $light, $dark); background: linear-gradient(to bottom, $light, $dark);}'+
            '#html body .bg-primary {background: $primary !important;}';

            // replace variables
            css = css.replace(/\$primary/g, b.colour).replace(/\$dark/g, b.colour_dark).replace(/\$light/g, b.colour_light);

            // update dom
            app.DOM.branding.html(css);
        }

        // do nothing for image
        if( !b.file && !b.file_local ) {
            return;
        }

        // show picture
        if( b.file_local ) {
            app.DOM.header_logo.attr('src', app.FILE.get_fs_dir(true, 'res') + b.file_local);
        } else if( b.file ) {
            app.DOM.header_logo.attr('src', app.CACHE.URL.uploads + app.CACHE.URL_FILES.res + b.file);
        }
    },

    allowed_redirect: function(btn)
    {
        if( app.HASH === 'rep_ans' && app.VIEW[app.HASH] && app.VIEW[app.HASH].DOM.btn_next.hasClass('button-green') === false ) {

            app.show_confirm("Are you sure you want to leave this check unanswered?", "Confirm action", function(response){
                if( response === true ) {
                    app.allowed_redirect_win(btn);
                }
            });

        } else if( app.VIEW[app.HASH] && app.VIEW[app.HASH].hasOwnProperty('UNSAVED') === true && app.VIEW[app.HASH].UNSAVED ) {

            app.show_confirm("Do you want to cancel changes you have made?", "Confirm action", function(response){
                if( response === true ) {
                   app.allowed_redirect_win(btn);
                    return;
                }
            });
        } else {

            app.allowed_redirect_win(btn);
        }
    },

    allowed_redirect_win: function(btn)
    {
        if( btn === 'home' ) {

            app.redirect('home');

        } else if( btn === 'back_form' ) {

            if( app.VIEW[app.HASH].DOM.form_btn_back.attr('href') ) {
                app.redirect( app.VIEW[app.HASH].DOM.form_btn_back.attr('href') );
            } else {
                window.history.back();
            }

        } else if( btn === 'back' ) {

            if( app.HASH === 'mai' && app.HISTORY[app.HISTORY.length -2] === 'mai_setup' ) {
                app.redirect('home');
            } else {
                window.history.back();
            }
        }
    },

    /**
     * Remove reports and answers from cache older than app.[TBL]_INTERVAL
     *
     * Daily Checks
     * Maintenance Inspections
     */
    check_reports_expired: function(tbl)
    {
        var tblU = tbl.toUpperCase(),
            tblUAns = tblU+'_ANS',
            field = ( tbl === 'mai' ) ? 'maintenance_id' : 'report_id';

        // do nothing
        if( !app.CACHE.USR.settings.logged_in || app.CACHE[tblU].length === 0 ) {
            return;
        }

        // setup vars
        var expired = moment().subtract(app[tblU+'_INTERVAL'], 'd').format('YYYY-MM-DD HH:mm:ss'), // time to wait before deleting
            rowAdd = [], // reports to add 
            rowDelete = [], // reports to delete
            answerAdd = []; // answers to add to reports

        // loop through reports and remove expired reports
        $.each(app.CACHE[tblU], function(k,v){
            if( v.hasOwnProperty('id') && (v.hasOwnProperty('date_start') && v.date_start < expired) || (v.user_id !== app.CACHE.USR.id) ) {
                rowDelete.push(v.id);
            } else {
                rowAdd.push(v);
            }
        });

        // no expired reports do nothing
        if( rowDelete.length === 0 ) {
            return;
        }

        // save live reports
        app.cache_save(tbl, rowAdd, false, function(){

            // loop through each answer to check for old report
            $.each(app.CACHE[tblUAns], function(k,v){

                // re-save answers that dont need deleting
                if( rowDelete.indexOf(v[field]) < 0 ) {
                    answerAdd.push(v);
                }
            });

            // lets go baby lets go
            app.cache_save(tbl+'_ans', answerAdd);
        });
    },

    /**
     * Setup events
     */
    setup_events: (init) => {
        if( init ) {

            // text zoom
            if( app.CACHE.DEVICE.platform === 'android' ) {
                TextZoom.set({ value: 1.0 }).catch((err) => console.log(`Error Setting Text Zoom: ${err.message}`));
            }

            // back button
            app.DOM.header_btn_bk.on('click', (e) => {
                app.allowed_redirect('back');
            });

            // menu button
            app.DOM.header_btn_home.on('click', (e) => {
                e.preventDefault();
                app.allowed_redirect('home');
            });

            if( app.hasOwnProperty('FLASHLIGHT')) {
                app.FLASHLIGHT.init();
            }

            // setup branding
            app.setup_branding();

            // delete reports older 30 days
            if( app.CACHE.USR.settings && app.CACHE.USR.settings.logged_in && (!window.location.hash || window.location.hash === '#home') ) {
                app.check_reports_expired('rep');
                app.check_reports_expired('mai');
            }

            if(Capacitor.getPlatform() === 'ios') {
                /*Keyboard.removeAllListeners()
                    .then(async () => await app.register_keyboard_show_listeners())
                    .catch(async () => await app.register_keyboard_show_listeners());*/
            }

        } else {

            // scroll to Top
            app.DOM.content.scrollTop(0);

            // clear footer centre
            app.DOM.footer_centre.text('');

            // check login
            app.check_user_login();

            // footer
            app.DOM.footer.hide();

            // check header
            app.setup_header_events_page_change();
        }
    },

    register_keyboard_show_listeners: async () => {
        Keyboard.addListener('keyboardDidHide', () => {
            if(!app.DOM.footer || !app.DOM.content_load) {
                return;
            }

            app.DOM.footer.css('bottom', '0');

            if(!app.HASH || !app.VIEW[app.HASH] || !app.VIEW[app.HASH].DOM || !app.VIEW[app.HASH].DOM.form) {
                return;
            }

            app.VIEW[app.HASH].DOM.form.css('margin-bottom', `0`);
        });

        Keyboard.addListener('keyboardWillHide', () => {
            if(!app.DOM.footer || !app.DOM.content_load) {
                return;
            }

            app.DOM.footer.css('bottom', '0');

            if(!app.HASH || !app.VIEW[app.HASH] || !app.VIEW[app.HASH].DOM || !app.VIEW[app.HASH].DOM.form) {
                return;
            }

            app.VIEW[app.HASH].DOM.form.css('margin-bottom', `0`);
        });

        Keyboard.addListener('keyboardDidShow', (info) => {
            if(!app.DOM.footer) {
                return;
            }

            app.DOM.footer.css('bottom', `${info.keyboardHeight}px`);

            if(!app.HASH || !app.VIEW[app.HASH] || !app.VIEW[app.HASH].DOM || !app.VIEW[app.HASH].DOM.form) {
                return;
            }

            app.VIEW[app.HASH].DOM.form.css('margin-bottom', `${info.keyboardHeight + 120}px`);
        });

        Keyboard.addListener('keyboardWillShow', (info) => {
            if(!app.DOM.footer) {
                return;
            }

            app.DOM.footer.css('bottom', `${info.keyboardHeight}px`);

            if(!app.HASH || !app.VIEW[app.HASH] || !app.VIEW[app.HASH].DOM || !app.VIEW[app.HASH].DOM.form) {
                return;
            }

            app.VIEW[app.HASH].DOM.form.css('margin-bottom', `${info.keyboardHeight + 120}px`);
        });
    },

    /**
     * Setup the header events when the page is changed
     */
    setup_header_events_page_change: function()
    {
        // show/hide header based on route
        if( app.ROUTE.header ) {
            app.DOM.header.show();
        } else {
            app.DOM.header.hide();
        }

        // check count for msgs to show alert
        app.check_header_msg_count();
    },

    /**
     * Update DOM with msg unread count
     */
    check_header_msg_count: function()
    {
        var unread = 0,
            unaccept = 0;

        $.each(app.CACHE.MSG_USR, function(k,r){
            if( r.hasOwnProperty('date_read') === false ) {
                unread++;
            }

            if( r.hasOwnProperty('date_accept') === false && r.is_accept === '1' ) {
                unaccept++;
            }
        });
        
        let msg = '';

        if(unread && unaccept) {
            msg = `<p><i class="fad fa-envelope"></i> ${unread} new ${Handlebars.helpers.plural(unread, 'message')} and ${unaccept} unacknowleged ${Handlebars.helpers.plural(unaccept, 'message')}</p>`;
        } else if(unread) {
            msg = `<p><i class="fad fa-envelope"></i> ${unread} new ${Handlebars.helpers.plural(unread, 'message')}</p>`;
        } else if(unaccept) {
            msg = `<p><i class="fad fa-envelope"></i> ${unaccept} unacknowleged ${Handlebars.helpers.plural(unaccept, 'message')}</p>`;
        }
        
        if( unread || unaccept ) {

            // what colour dot will be?
            var addClass = ( unread ) ? 'bg-green' : 'bg-orange';

            app.DOM.header_btn_msg_span.removeClass('bg-green bg-orange').addClass(addClass).show();
            app.DOM.content.find('#unread-or-unaccept-msg').css('display', 'block').html(msg);
        } else {
            app.DOM.header_btn_msg_span.hide();
            app.DOM.content.find('#unread-or-unaccept-msg').css('display', 'none');
        }
    },

    /**
     * Update app.CACHE[ tbl ] variable
     */
    cache_save: function(tbl, value, sync, callback)
    {
        // use local storage if empty
        if( value === undefined || value === false ) {
            value = app.CACHE[ tbl.toUpperCase() ];
        }

        console.log('#################');
        console.log('Saving -> ', tbl);
        console.log('#################');

        // localForage API
        if( app.TBL[tbl.toLowerCase()] && app.TBL[tbl.toLowerCase()].hasOwnProperty('localforage') ) {

            if( sync ) {
                // delay for async call
                setTimeout(function(){ localforage.setItem(tbl.toLowerCase(), value).catch((err) => console.warn(err)); }, 250 );
            } else {
                // run straight away
                localforage.setItem(tbl.toLowerCase(), value, function(err){
                    if(err) {
                        console.warn(err);
                        return;
                    }

                    if( err === null && typeof(callback) === 'function' ) {
                        callback.call();
                    } else if( err !== null ) {
                        console.error('Could not save record', tbl, err);
                    }
                });
            }

        } else {
            // localStorage API: synchronous call
            localStorage.setItem(tbl.toLowerCase(), JSON.stringify(value));

            // callback
            if( typeof(callback) === 'function' ) {
                callback.call();
            }
        }

        // storage in local app.CACHE
        app.CACHE[tbl.toUpperCase()] = value;
    },

    /**
     * Get index of array from row ts|id
     */
    cache_get_index: function(tbl, data, field)
    {
        var identifier;

        // which identifier will return index of row
        if( typeof(data) === 'object' ) {
            identifier = data.id || data.ts;
        } else {
            identifier = data;
        }

        // do not proceed with trying to find item
        if( identifier === undefined ) {
            return false;
        }

        // lowercase tbl to avoid confusion
        tbl = tbl.toLowerCase();

        var rows = app.CACHE[tbl.toUpperCase()];

        if(!rows || rows.length === 0) {
            console.warn('error', 'Could NOT find '+tbl +' item', 'There was a problem');
            return false;
        }

        // search for index
        for(var i = 0; i < rows.length; i++){

            // check if theres a match
            if(
                (!field && (rows[i].id == identifier || rows[i].ts == identifier)) ||
                (field && rows[i][field] == identifier)
            ) {
                return i;
            }
        }

        return false;
    },

    /**
     * Get property/properties from app.CACHE[tbl]
     */
    cache_get_prop: function(tbl, identifier, prop)
    {
        // get tbl from CACHE
        var c = app.CACHE[ tbl.toUpperCase() ];

        // check if identify is object
        if( typeof identifier === 'object' ) {
            identifier = ( identifier.hasOwnProperty('id') ) ? identifier.id : identifier.ts;
        }

        for (var i = 0; i < c.length; i++) {

            if( c[i].id === identifier || c[i].ts == identifier ) {

                // more than one property to return
                if( typeof(prop) === 'object' ) {

                    var tmp = '';

                    $.each(prop, function(){
                        tmp += c[i][this] += ' ';
                    });

                    return tmp.trim();
                }

                return c[i][prop];
            }
        }

        return '';
    },

    /**
     * Add item to app.CACHE[tbl] array
     */
    cache_add: function(tbl, data)
    {
        // update global var
        app.CACHE[ tbl.toUpperCase() ].push( data );

        // update localStorage
        app.cache_save(tbl);
    },

    /**
     * Asynchronous adding function for localforage
     */
    forage_add: function(tbl, data)
    {
        try {
            localforage.setItem(tbl, data);

            app.CACHE[tbl.toUpperCase()] = data;
        } catch(err) {
            console.warn(err);
        }
    },

    /**
     * Edit item to cache
     */
    cache_edit: function(tbl, data)
    {
        var index = app.cache_get_index(tbl, data);

        // do nothing
        if( index === false ) {
            return;
        }

        // update global var
        items[ index ] = data;

        // update localStorage
        app.cache_save(tbl);
    },

    cache_delete: function(tbl, data)
    {
        var index = app.cache_get_index(tbl, data);

        // do nothing
        if( index === false ) {
            return;
        }

        // update global var
        items.splice(index, 1);

        // update localStorage
        app.cache_save(tbl);
    },

    /**
     * Setup app.URI
     * Setup app.HASH
     * Setup app.HASHCHANGE
     * Setup app.ROUTE
     */
    setup_routing: function(init)
    {
        app.URI = window.location.hash.replace('#', '').split('/');
        app.HASH = this.get_hash();
        app.HASHCHANGE = (init === true) ? false : true;

        // put page in uri if not multiple
        if( app.URI.length === 0 ){
            app.URI.push(hash);
        }
        
        // set initial route if we cant find any matching
        app.ROUTE = {};

        // setup intial route
        if( app.ROUTES.hasOwnProperty(app.HASH) ) {
            app.ROUTE = app.ROUTES[app.HASH];
            app.ROUTE.template = app.HASH;
        } else {
            app.ROUTE = app.ROUTES.error;
        }
    },

    get_hash: function()
    {
        let hash = app.URI[0];

        // update timeoute reference
        app.TIMEOUT.check();

        if( hash === 'logout' ) {
            return hash;
        }

        if( hash === 'login' && app.CACHE.USR.settings.logged_in ) {

            return 'home';

        } else if ( hash.indexOf('errors') < 0 && hash.indexOf('admin') < 0 && hash.indexOf('pin') < 0 && app.CACHE.USR.settings && app.CACHE.USR.settings.timeout ) {

            return 'timeout';

        } else if (
            ( app.CACHE.USR.settings && app.CACHE.USR.settings.logged_in && ( !app.CACHE.USR.settings.sitename || app.CACHE.USR.settings.sitename !== app.CACHE.SITENAME ) ) ||
            ( app.CACHE.USR.settings && hash === '' && app.CACHE.USR.settings.logged_in === false ) ||
            ( app.CACHE.USR.settings && app.ROUTES.hasOwnProperty(hash) && app.ROUTES[hash].secure === true && app.CACHE.USR.settings.logged_in === false )
            ) {

            // select home route
            return 'login';

        } else if( hash.indexOf('errors') < 0 && hash.indexOf('admin') && app.CACHE.USR.settings && app.CACHE.USR.settings.logged_in && ( app.CACHE.USR.logins_app === '0' || app.CACHE.USR.pin_reset_force === '1' ) ) {

            return 'pin_first';

        } else if(
            ( hash === '' && app.CACHE.USR.settings && app.CACHE.USR.settings.logged_in ) ||
            ( hash !== '' && app.CACHE.USR.settings && app.CACHE.USR.settings.logged_in && ( app.CACHE.USR.is_deleted === '1' || app.CACHE.USR.is_suspended === '1' ) && app.ROUTES[hash].secure )
        ) {

            return 'home';

        }
         

        return hash;
    },

    obj_length: function(obj)
    {
        var count = 0;

        if( obj.hasOwnProperty('length') ) {
            return obj.length;
        }

        $.each(obj, function(k,v){
            count++;
        });

        return count;
    },

    /**
     *
     * Dependant on desktop/app as to which function is called
     *
     * @param string message
     * @param string title
     */
    show_alert: function(message, title)
    {
        if( title === undefined ) {
            title = 'Attention';
        }

        if(app.WEBAPP) {
            app.MODAL.create(title, message, 'OK', '#modal-container').then((() => {})).catch((err) => {
                console.error(err);
            });
            return;
        }

        Dialog.alert({
            message: message,
            title: title,
            buttonTitle: 'OK'
        }).catch((err) => console.warn(`Something went wrong displaying alert dialog: ${err.message}`));
    },

    show_confirm: function(message, title, callback, callbackData, buttonLabels)
    {
        var customLabels = ( buttonLabels === undefined || buttonLabels === false ) ? false : true;

        // choose correct labels
        buttonLabels = ( customLabels  ) ? buttonLabels : ['OK', 'Cancel'];

        if(app.WEBAPP) {
            let opts = [];
            let custom = buttonLabels.length > 2;
            buttonLabels.forEach((button, index) => {
                if(typeof button === "string") {
                    opts.push({ title: button, style: index === buttonLabels.length ? ActionSheetButtonStyle.Cancel : ActionSheetButtonStyle.Default });
                    return;
                }

                opts.push(button);
            });

            app.ACTION_SHEET.create(
                title,
                message,
                opts,
                '#action-sheet-container'
            ).then((selectedAction) => {
                callback.call(this, custom ? selectedAction : selectedAction === 0, callbackData);
            }).catch((err) => {
                console.error(err);
            });
            return;
        }

        Dialog.confirm({
            title: title,
            message: message,
            okButtonTitle: buttonLabels[0],
            cancelButtonTitle: buttonLabels[1],
        }).then((action) => {
            callback.call(this, action.value, callbackData);
        }).catch((err) => {
            console.error(`Error Showing Confirm Dialog: ${err.message}`);
        });
    },

    /**
     * Redirect user to said page
     *
     * If location empty refresh page
     */
    redirect: function(location)
    {
        console.log('~~~~~~~~~~~~~~~~~~~');

        if( location === undefined ) {
            // refresh page
            console.log('REFRESHING: #' + app.HASH);
            console.log('~~~~~~~~~~~~~~~~~~~');
            window.location.reload();
        } else {
            // redirect page
            console.log('REDIRECTING... #' +  location.replace('#', '') );
            console.log('~~~~~~~~~~~~~~~~~~~');
            window.location = 'index.html#' + location.replace('#', '');
        }
    },

    redirectAsync: async (location) => {
        return new Promise((resolve) => {
            console.log('~~~~~~~~~~~~~~~~~~~');
            if( location === undefined ) {
                // refresh page
                console.log('REFRESHING: #' + app.HASH);
                console.log('~~~~~~~~~~~~~~~~~~~');
                window.location.reload();
            } else {
                // redirect page
                console.log('REDIRECTING... #' +  location.replace('#', '') );
                console.log('~~~~~~~~~~~~~~~~~~~');
                window.location = 'index.html#' + location.replace('#', '');
            }
            resolve();
        });
    },

    // simple helper for create html w/o undefined text
    val: function(val)
    {
        if( !val ){
            return '';
        }

        return val;
    },
};

// START
(async () => {
    await app.init();
    window._  = function(dom, set)
    {
        return app._(dom, set);
    }
})();
